<section class="file-panel">
    <!-- start: file tabs -->
    <section *ngIf="!toggleContactInfo" class="file-tabs">
        <!-- start: file tabs header -->
        <article class="file-tabs__header">
            <!-- <button class="left-arrow-transaction" *ngIf="isFromDashboard">
                <img src="./assets/icons/icon-arrow-left-dark.svg" alt="left-arrow" />
            </button> -->
            <div *ngIf="!additionalInfoCheck">
                <h2>{{ header }}</h2>
                    <div class="text-muted">
                        #{{ fileNumber }}
                        <span *ngIf="address"> | {{ address }} </span>
                    </div>
            </div>
            <div class="info-card__row" *ngIf="additionalInfoCheck">
                <button class="left-arrow-transaction" (click)="backArrow()" *ngIf="additionalInfoCheck">
                    <!-- <lib-icon name = "arrow_left"></lib-icon> -->
                    <img class="img"
                    src="./assets/icons/icon-arrow-left-dark.svg"
                    alt="calendar icon"
                />
                </button>
                    <!-- <div>
                        <h1>
                            <span class="txt-overflow group-name-width" [matTooltip]="additionalInfoEntityName" showTooltipOnElipsis>{{ additionalInfoEntityName }}</span>
                        </h1>
                        <div class="info-card__col-left buyer-seller-types">
                            {{ partyDetails[additionalInfoIndex].itemName }}
                        </div>
                    </div> -->
            </div>
        </article>
        <!-- end: file tabs alert -->

        <!--
            FOR LAYOUT WITH OPEN FILE BUTTON
            USE THIS (ADD THE CLASS .with-open-button)
            <mat-tab-group class="ig2 mat-tab-group-nested with-open-button" animationDuration="0ms">
        -->
        
        <div *ngIf="additionalInfoCheck" class="addtitional-info-scroll"> 
            <section name="partiesAdditionalInfo" class="info-card parties-additional-info-card">
             <h3>Contact Info</h3> 
                 <div class="parties_container-Details-Card">
                     <div class="" *ngIf="partyDetails[additionalInfoIndex].dtoType === 'businessContacts'">
                         <section *ngFor="let item of partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts; index as k">
                             <article class="info-card__row detail-info-card"  *ngIf="partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts[k]?.cellulars?.length>0">
                             <div class="info-tab-card__col-left">Mobile Phone</div>
                                 <div class="info-tab-card__col-right" 
                                 [innerHTML]="value" *ngFor="let value of partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts[k]?.cellulars;">
                                 </div>
                             </article>
                             <article class="info-card__row detail-info-card"  *ngIf="partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts[k]?.homePhones?.length>0">
                                 <div class="info-tab-card__col-left">Home Phone</div>
                                     <div class="info-tab-card__col-right" 
                                     [innerHTML]="value" *ngFor="let value of partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts[k]?.homePhones;">
                                     </div>
                                 </article>
                             <article class="info-card__row detail-info-card"  *ngIf="partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts[k]?.businessPhones?.length>0">
                             <div class="info-tab-card__col-left">Bus. Phone</div>
                                 <div class="info-tab-card__col-right" 
                                 [innerHTML]="value" *ngFor="let value of partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts[k]?.businessPhones;">
                                 </div>
                             </article>
                             
                             <article class="info-card__row detail-info-card"  *ngIf="partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts[k]?.forwardingPhones?.length>0">
                                 <div class="info-tab-card__col-left">Fwd. Phone</div>
                                     <div class="info-tab-card__col-right" 
                                     [innerHTML]="value" *ngFor="let value of partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts[k]?.forwardingPhones;">
                                     </div>
                                 </article>
                             <!-- <div class="bussiness-info-left-details-space" *ngIf="partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts[k]?.forwardingPhones?.length>0 && partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts[k]?.homeFaxes?.length>0" >
                             </div> -->
                             <article class="info-card__row detail-info-card" *ngIf="partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts[k]?.homeFaxes?.length>0">
                                 <div class="info-tab-card__col-left">Home Fax</div>
                                     <div class="info-tab-card__col-right" 
                                     [innerHTML]="value" *ngFor="let value of partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts[k]?.homeFaxes;">
                                     </div>
                                 </article>
                             <article class="info-card__row detail-info-card"  *ngIf="partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts[k]?.businessFaxes?.length>0">
                                 <div class="info-tab-card__col-left">Bus. Fax</div>
                                     <div class="info-tab-card__col-right" 
                                     [innerHTML]="value" *ngFor="let value of partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts[k]?.businessFaxes;">
                                     </div>
                             </article>    
                             <article class="info-card__row detail-info-card"  *ngIf="partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts[k]?.emails?.length>0">
                             <div class="info-tab-card__col-left">Email</div>
                                 <div class="info-tab-card__col-right emals-data" 
                                     [innerHTML]="value" *ngFor="let value of partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts[k]?.emails;">
                                 </div>
                             </article>
                             <article class="info-card__row detail-info-card"  *ngIf="partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts[k]?.currentaddress">
                             <div class="info-tab-card__col-left">Current Address</div>
                                 <div class="info-tab-card__col-right address-data" 
                                 [innerHTML]="partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts[k]?.currentaddress">
                                 </div>
                             </article>
                             <div class="bussiness-info-left-details-space" *ngIf="partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts[k]?.currentaddress?.length>0 && partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts[k]?.forwardingAddress?.length>0" >
                             </div> 
                             <article class="info-card__row detail-info-card"  *ngIf="partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts[k]?.forwardingAddress">
                             <div class="info-tab-card__col-left">Fwd. Address</div>
                                 <div class="info-tab-card__col-right address-data" 
                                 [innerHTML]="partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts[k]?.forwardingAddress">
                                 </div>
                             </article>
                             <article class="info-card__row detail-info-card"  *ngIf="partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts[k]?.address">
                                 <div class="info-tab-card__col-left">Address</div>
                                     <div class="info-tab-card__col-right address-data" 
                                     [innerHTML]="partyDetails[additionalInfoIndex].partyInformation?.itemObject?.contacts[k]?.address">
                                     </div>
                                 </article>
                         </section>
                     </div>
                     <section  *ngFor="let item of partyDetails[additionalInfoIndex]?.itemObject?.contacts; index as k">
                         <div class="bussiness-info-card" *ngIf="partyDetails[this.additionalInfoIndex].partyInformation != null && partyDetails[additionalInfoIndex].itemObject!= null">
                         </div>          
                         <article class="contact-wrapper contact-name"
                         *ngIf="partyDetails[this.additionalInfoIndex].itemObject.templateType !== 'individual'">
                             <div [innerHTML]="item.contactFullName">
                             </div>
                         </article>
                         <article class="info-card__row detail-info-card"  *ngIf="partyDetails[additionalInfoIndex].itemObject?.contacts[k]?.cellulars?.length>0">
                         <div class="info-tab-card__col-left">Mobile Phone</div>
                             <div class="info-tab-card__col-right" 
                             [innerHTML]="value" *ngFor="let value of partyDetails[additionalInfoIndex].itemObject?.contacts[k]?.cellulars;">
                             </div>
                         </article>
                         <article class="info-card__row detail-info-card"  *ngIf="partyDetails[additionalInfoIndex].itemObject?.contacts[k]?.homePhones?.length>0">
                             <div class="info-tab-card__col-left">Home Phone</div>
                                 <div class="info-tab-card__col-right" 
                                 [innerHTML]="value" *ngFor="let value of partyDetails[additionalInfoIndex].itemObject?.contacts[k]?.homePhones;">
                                 </div>
                         </article>
                         <article class="info-card__row detail-info-card"  *ngIf="partyDetails[additionalInfoIndex].itemObject?.contacts[k]?.businessPhones?.length>0">
                         <div class="info-tab-card__col-left">Bus. Phone</div>
                             <div class="info-tab-card__col-right" 
                             [innerHTML]="value" *ngFor="let value of partyDetails[additionalInfoIndex].itemObject?.contacts[k]?.businessPhones;">
                             </div>
                         </article>
                         <article class="info-card__row detail-info-card"  *ngIf="partyDetails[additionalInfoIndex].itemObject?.contacts[k]?.forwardingPhones?.length>0">
                             <div class="info-tab-card__col-left">Fwd. Phone</div>
                                 <div class="info-tab-card__col-right" 
                                 [innerHTML]="value" *ngFor="let value of partyDetails[additionalInfoIndex].itemObject?.contacts[k]?.forwardingPhones;">
                                 </div>
                         </article>
                         <div class="bussiness-info-left-details-space" *ngIf="partyDetails[additionalInfoIndex].itemObject?.contacts[k]?.forwardingPhones?.length>0 && partyDetails[additionalInfoIndex].itemObject?.contacts[k]?.homeFaxes?.length>0" >
                         </div>
                         <article class="info-card__row detail-info-card" *ngIf="partyDetails[additionalInfoIndex].itemObject?.contacts[k]?.homeFaxes?.length>0">
                             <div class="info-tab-card__col-left">Home Fax</div>
                                 <div class="info-tab-card__col-right" 
                                 [innerHTML]="value" *ngFor="let value of partyDetails[additionalInfoIndex].itemObject?.contacts[k]?.homeFaxes;">
                                 </div>
                             </article>
                         <article class="info-card__row detail-info-card"  *ngIf="partyDetails[additionalInfoIndex].itemObject?.contacts[k]?.businessFaxes?.length>0">
                             <div class="info-tab-card__col-left">Bus. Fax</div>
                                 <div class="info-tab-card__col-right" 
                                 [innerHTML]="value" *ngFor="let value of partyDetails[additionalInfoIndex].itemObject?.contacts[k]?.businessFaxes;">
                                 </div>
                             </article>
                         <article class="info-card__row detail-info-card"  *ngIf="partyDetails[additionalInfoIndex].itemObject?.contacts[k]?.emails?.length>0">
                         <div class="info-tab-card__col-left">Email</div>
                             <div class="info-tab-card__col-right emals-data" 
                                 [innerHTML]="value" *ngFor="let value of partyDetails[additionalInfoIndex].itemObject?.contacts[k]?.emails;">
                             </div>
                         </article>
                         <article class="info-card__row detail-info-card"  *ngIf="partyDetails[additionalInfoIndex].itemObject?.contacts[k]?.currentaddress">
                         <div class="info-tab-card__col-left">Current Address</div>
                             <div class="info-tab-card__col-right address-data" 
                             [innerHTML]="partyDetails[additionalInfoIndex].itemObject?.contacts[k]?.currentaddress">
                             </div>
                         </article>
                         <div class="bussiness-info-left-details-space" *ngIf="partyDetails[additionalInfoIndex].itemObject?.contacts[k]?.currentaddress?.length>0 && partyDetails[additionalInfoIndex].itemObject?.contacts[k]?.forwardingAddress?.length>0" >
                         </div> 
                         <article class="info-card__row detail-info-card"  *ngIf="partyDetails[additionalInfoIndex].itemObject?.contacts[k]?.forwardingAddress">
                         <div class="info-tab-card__col-left">Fwd. Address</div>
                             <div class="info-tab-card__col-right address-data" 
                             [innerHTML]="partyDetails[additionalInfoIndex].itemObject?.contacts[k]?.forwardingAddress">
                             </div>
                         </article>
                         <article class="info-card__row detail-info-card"  *ngIf="partyDetails[additionalInfoIndex].itemObject?.contacts[k]?.address">
                             <div class="info-tab-card__col-left">Address</div>
                                 <div class="info-tab-card__col-right address-data"
                                 [innerHTML]="partyDetails[additionalInfoIndex].itemObject?.contacts[k]?.address">
                                 </div>
                             </article>
                         <div class="bussiness-info-card-details-space" *ngIf="partyDetails[this.additionalInfoIndex].itemObject.templateType === 'husbandWife'">
                         </div>
                     </section>
                 </div>
             </section> 
         </div>
   
        <mat-tab-group [ngClass]="additionalInfoCheck?'hidden':''"  class="ig2 mat-tab-group-nested panel-height" 
            disableRipple animationDuration="0ms" [selectedIndex]="selectedIndex">
            <mat-tab label="Transaction">
                <lib-info-card header="Transaction Details" [items]="transactionDetails"></lib-info-card>
                <lib-info-card header="Transaction Team" [items]="transactionTeams"></lib-info-card>
            </mat-tab>
            <mat-tab label="Parties">
                <div class="parties_container">
                    <section class="info-card parties-card arrow-icon-parent" *ngFor="let item of partyDetails; index as i">
                        <section name="partiesContent" class="info-card__body">
                            <article class="info-card__row">
                                <div class="info-card__col-left">
                                    {{ item.itemName }}
                                </div>
                                <div class="info-card__col-right" [ngSwitch]="item.itemObject?.templateType">
                                    <div *ngSwitchCase="'individual'">
                                        <div *ngFor="let contact of item?.itemObject?.markup; index as i">
                                            <div class="contact-wrapper">
                                                <secure-portal-badge *ngIf="contact?.principalId"
                                                    [principalId]="contact?.principalId"
                                                    [contactId]="contact?.contactId">
                                                </secure-portal-badge>
                                                <div [innerHTML]="contact.contactName">
                                                </div>
                                            </div>
                                            <div class="contact__wrapper"
                                                [innerHTML]="item?.itemObject?.markup[i]?.phone">
                                            </div>
                                            <div class="contact__wrapper"
                                                [innerHTML]="item?.itemObject?.markup[i]?.email">
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'husbandWife'">
                                        <div *ngFor="let contact of item?.itemObject?.markup; index as i" class="multi">
                                            <div class="contact-wrapper">
                                                <secure-portal-badge *ngIf="contact?.principalId"
                                                    [principalId]="contact?.principalId"
                                                    
                                                    [isPrimary]="contact?.isPrimary" [contactId]="contact?.contactId">
                                                </secure-portal-badge>
                                                <div [innerHTML]="contact.contactName">
                                                </div>
                                            </div>
                                            <div class="contact__wrapper"
                                                [innerHTML]="item?.itemObject?.markup[i]?.phone">
                                            </div>
                                            <div class="contact__wrapper"
                                                [innerHTML]="item?.itemObject?.markup[i]?.email">
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'businessTrust'">
                                        <ng-container
                                            *ngIf="item?.itemObject?.markup[0]?.contactName && item?.itemObject?.markup[0]?.contactName?.length > 0">
                                            <div class="org-name"
                                                [innerHTML]="item?.itemObject?.contacts[0].businessOrganizationName">
                                            </div>
                                            <div *ngFor="let contact of item?.itemObject?.contacts; index as i"
                                                class="multi">
                                                <div class="contact-wrapper">
                                                    <secure-portal-badge [principalId]="contact?.principalId"
                                                     
                                                        [contactId]="contact?.contactId">
                                                    </secure-portal-badge>
                                                    <div [innerHTML]="item?.itemObject?.markup[i]?.contactName">
                                                    </div>
                                                </div>
                                                <div class="contact__wrapper"
                                                    [innerHTML]="item?.itemObject?.markup[i]?.phone">
                                                </div>
                                                <div class="contact__wrapper"
                                                    [innerHTML]="item?.itemObject?.markup[i]?.email">
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div *ngSwitchCase="'org'">
                                        <div *ngFor="let contact of item?.itemObject?.contacts; index as i">
                                            <div class="contact-wrapper">
                                                <div [innerHTML]="item?.itemObject?.markup[i]?.name">
                                                </div>
                                            </div>
                                            <div *ngIf="item?.itemObject?.contacts[0]?.roleTypeId !=326 && item?.itemObject?.contacts[0]?.roleTypeId !=323">
                                            <div class="contact__wrapper"
                                                [innerHTML]="item?.itemObject?.markup[i]?.phone">
                                            </div>
                                            <div class="contact__wrapper"
                                                [innerHTML]="item?.itemObject?.markup[i]?.email">
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'orgNoContactName'">
                                        <div class="contact-wrapper">
                                            <div [innerHTML]="item?.itemValue">
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'orgWithBadge'">
                                        <div *ngFor="let contact of item?.itemObject?.contacts; index as i">
                                            <div class="contact-wrapper"
                                                *ngIf="item?.itemObject?.markup[i]?.name && item?.itemObject?.markup[i]?.name?.length > 0">
                                                <secure-portal-badge [principalId]="contact?.id"
                                                  >
                                                </secure-portal-badge>
                                                <div [innerHTML]="item?.itemObject?.markup[i]?.name">
                                                </div>
                                            </div>
                                            <div class="contact__wrapper"
                                                [innerHTML]="item?.itemObject?.markup[i]?.phone">
                                            </div>
                                            <div class="contact__wrapper"
                                                [innerHTML]="item?.itemObject?.markup[i]?.email">
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'lender'">
                                        <div *ngFor="let contact of item?.itemObject?.contacts; index as i">
                                            <div class="contact-wrapper"
                                                *ngIf="item?.itemObject?.markup[i]?.name && item?.itemObject?.markup[i]?.name?.length > 0">
                                                <secure-portal-badge [principalId]="contact?.id"
                                                   >
                                                </secure-portal-badge>
                                                <div [innerHTML]="item?.itemObject?.markup[i]?.name">
                                                </div>
                                            </div>
                                            <div class="contact__wrapper"
                                                [innerHTML]="item?.itemObject?.markup[i]?.phone">
                                            </div>
                                            <div class="contact__wrapper"
                                                [innerHTML]="item?.itemObject?.markup[i]?.email">
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'attorneyAgent'">
                                        <ng-container
                                            *ngIf="item?.itemObject?.markup[0].contactName && item?.itemObject?.markup[0]?.contactName?.length > 0">
                                            <div [innerHTML]="item?.itemObject?.markup[0].name"></div>
                                            <div *ngFor="let contact of item?.itemObject?.contacts; index as i">
                                                <div class="contact-wrapper">
                                                    <secure-portal-badge [principalId]="contact?.id"
                                                      >
                                                    </secure-portal-badge>
                                                    <div [innerHTML]="item?.itemObject?.markup[i]?.contactName">
                                                    </div>
                                                </div>
                                                <div class="contact__wrapper"
                                                    [innerHTML]="item?.itemObject?.markup[i]?.phone">
                                                </div>
                                                <div class="contact__wrapper"
                                                    [innerHTML]="item?.itemObject?.markup[i]?.email">
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div *ngSwitchCase="'SellerBrokerAgent'">
                                        <ng-container
                                            *ngIf="item?.itemObject?.markup[0].contactName && item?.itemObject?.markup[0]?.contactName?.length > 0">
                                            <div [innerHTML]="item?.itemObject?.markup[0]?.name"></div>
                                            <div *ngFor="let contact of item?.itemObject?.contacts; index as i">

                                                <div class="contact-wrapper">
                                                    <secure-portal-badge [principalId]="item?.itemObject?.contacts[i]?.id"
                                                       >
                                                    </secure-portal-badge>
                                                    <div [innerHTML]="item?.itemObject?.markup[i]?.contactName">
                                                    </div>
                                                </div>
                                                <div class="contact__wrapper"
                                                    [innerHTML]="item?.itemObject?.markup[i]?.phone">
                                                </div>
                                                <div class="contact__wrapper"
                                                    [innerHTML]="item?.itemObject?.markup[i]?.email">
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <!-- Donnot remove required in upcoming release -->
                                <div class="info-card__col-additionalInfoArrow dv-info-arrow">
                                   <!-- <lib-icon name = "arrow_right" class="arrow-icon parties-arrow_right"></lib-icon> -->
                                  <img class = "img-arrow-on-hover"
                                  src="./assets/icons/icon-arrow-right-dark.svg"
                                  alt="calendar icon"
                              />
                                </div>
                            </article>
                        </section>
                    </section>
                </div>
            </mat-tab>
        </mat-tab-group>
    </section>
