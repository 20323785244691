import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { FileSearchComponent } from './components/file-search/file-search.component';
import { environment } from 'src/environments/environment';
import { FileSearchResultsComponent } from './components/file-search-results/file-search-results.component';
import { EmailSearchComponent } from './components/email-search/email-search.component';


const routes: Routes = [
  {
    path: environment.appPath,
    children: [
      {
        path: '',
        component: FileSearchComponent
      },
      {
        path: ':searchType/:searchField',
        component: FileSearchResultsComponent
      },
    ],
  },
  {
    path: 'email-search',
    children: [
      {
        path: '',
        component: EmailSearchComponent,
      },
      {
        path: ':searchType/:searchField',
        component: FileSearchResultsComponent
      },
    ]
  },
  { path: '**', component: EmptyRouteComponent }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }],
})
export class AppRoutingModule { }
