import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { SubscriptionLike as ISubscription, Subject, distinctUntilChanged, tap, switchMap, of, takeUntil } from 'rxjs';
import { SearchModes } from 'src/app/global/enums/search-mode-enums';
import { SearchPlaceholders } from 'src/app/global/enums/search-placeholders.enum';
import { SearchTypes } from 'src/app/global/enums/search-types.enum';
import { Predictive } from 'src/app/global/models/file-search/predictive-dto';
import { RecentSearchResultDto } from 'src/app/global/models/recent-search-result-dto';
import { getUserInfo } from '@igps/client-root-config';
import { IgpsDebounce } from 'src/app/global/decorator/igps-debounce';
import { SearchDescription } from 'src/app/global/models/search-description';
import { environment } from 'src/environments/environment';
import { FileSearchService } from 'src/app/global/services/file-search.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-file-search',
  templateUrl: './file-search.component.html',
  styleUrls: ['./file-search.component.scss']
})
export class FileSearchComponent implements OnInit {
  @Input() public isEmailSearch: boolean = false;
  @Input() public emailId: any;
  public placeholder = 'Search file numbers, addresses, parties...';
  public selectedType = 'All';
  public searchMode = SearchModes.Inactive;
  public showSearchResultsDropdown: boolean;
  public searchCharacterThreshold: number = 3;
  public isSearchThresholdMet = false;
  public recentSearched = false;
  public predictiveSearchResults: Predictive[] = [];
  public recentSearches: RecentSearchResultDto[] = [];
  public searchPlaceholders = SearchPlaceholders;
  public queryField: UntypedFormControl = new UntypedFormControl();
  public searchTypes = SearchTypes;
  public isThereSearchInput = false;
  private searchSubject: Subject<string> = new Subject();
  public cancelSearch = new Subject<boolean>();
  public subscribeObjectList: ISubscription[] = [];
  public taskSource: number;
  state!: any;
  selectedEmailStatusId: number | undefined;
  @ViewChild('searchInput') public searchInput!: ElementRef;
  searchTypesKeys: any[];
  userInfo!: any;
  processName: any;

  constructor(private location: Location, private router: Router, private SearchResultService: FileSearchService) {
    this.state = this.location.getState();
    this.emailId = this.state?.emailId ? this.state?.emailId : sessionStorage.getItem('selectedEmailNoteTaskId');
  }
  ngOnInit(): void {
    this.userInfo = getUserInfo();
    this.searchTypesKeys = Object.keys(this.searchTypes).filter((type) => {
      return type;
    });
    this.processSearchInputChanges();
    this.SearchResultService.getprocessdetails(sessionStorage.getItem('ActiveProcessId')).subscribe((res: any) => {
      if (res) {
        //  onChangeProcess.emit({ id: res.id, name: res.name, processorAddTask: res.processorAddTask, taskSource: res.taskSource });
        this.taskSource = res.taskSource;
        this.processName = res.name;
      }
    })
  }


  public processSearchInputChanges(): void {
    let searchText = '';

    const inputChanges = this.searchSubject
      .pipe(
        distinctUntilChanged(),
        tap((result) => {
          searchText = result;
          this.SearchResultService.searchObject.searchText = result;
          this.SearchResultService.searchObject.searchType = this.selectedType;
        }),
        switchMap((result) => {
          this.searchMode = SearchModes.Searching;
          if (result && result.length >= this.searchCharacterThreshold) {
            this.isSearchThresholdMet = true;
            this.recentSearched = false;
            return this.SearchResultService.getPredictiveSearchResults(this.selectedType, result).pipe(takeUntil(this.cancelSearch));
          } else {
            this.isSearchThresholdMet = false;
            if (this.recentSearched === false) {
              this.recentSearched = true;
              return this.SearchResultService.getRecentSearchResults(this.selectedType, this.userInfo?.basicInfo?.email.split('@')[0]);
            } else {
              this.recentSearched = true;
              return of(this.recentSearches);
            }
          }
        })
      )
      .subscribe((results) => {
        this.populateResults(this.selectedType, results, searchText);
      });

    this.subscribeObjectList.push(inputChanges);
  }
  public showSearchResults(): void {
    this.placeholder = '';
    this.searchMode = SearchModes.Searching;
    this.showSearchResultsDropdown = true;
    const query = this.searchInput.nativeElement.value || '';
    this.conductSearch(this.selectedType, query);
  }
  public closeSearchResultsDropdown(): void {
    this.showSearchResultsDropdown = false;
    this.placeholder = this.searchPlaceholders[this.selectedType];
    this.recentSearches = <RecentSearchResultDto[]>[];
    this.recentSearched = false;
  }
  public conductSearch(category: string, searchText: string): void {
    this.searchMode = SearchModes.Searching;
    if (this.thresholdMet(searchText)) {
      this.isSearchThresholdMet = true;
      this.recentSearched = false;
      const predictive = this.SearchResultService.getPredictiveSearchResults(category, searchText)
        .pipe(takeUntil(this.cancelSearch))
        .subscribe((results) => {
          var results = results;
          this.populateResults(category, results, searchText);
        });
      this.subscribeObjectList.push(predictive);
    } else {
      this.isSearchThresholdMet = false;

      if (this.recentSearched === false) {
        this.recentSearched = true;
        const recent = this.SearchResultService.getRecentSearchResults(category, this.userInfo?.basicInfo?.email.split('@')[0])
          .pipe(takeUntil(this.cancelSearch))
          .subscribe((results) => {
            this.populateResults(category, results, searchText);
          });
        this.subscribeObjectList.push(recent);
      } else {
        this.recentSearched = true;
        this.populateResults(category, this.recentSearches, searchText);
      }
    }
  }
  public thresholdMet(text: string): boolean {
    if (text && text.length >= this.searchCharacterThreshold) { return true; }
    return false;
  }
  public populateResults(category: string, results: any, searchText: string): void {

    if (results.length === 0) {
      this.searchMode = this.getSearchMethod(searchText, 0);
      this.predictiveSearchResults = <Predictive[]>[];
      this.recentSearches = <RecentSearchResultDto[]>[];
    } else {
      const firstResult = results[0];

      // if (firstResult && firstResult.hasOwnProperty('category')) {
      if (firstResult && Object.prototype.hasOwnProperty.call(firstResult, 'category')) {
        this.searchMode = SearchModes.PredictivesFound;
        const categoryCode = this.searchTypes[category];
        if (category !== 'All' && firstResult.category !== categoryCode) {
          this.searchMode = SearchModes.NoCategoryResults;
        }
        this.recentSearches = <RecentSearchResultDto[]>[];
        this.predictiveSearchResults = <Predictive[]>[...results];
      } else {
        this.searchMode = SearchModes.RecentsFound;
        this.recentSearches = <RecentSearchResultDto[]>[...results];
        this.predictiveSearchResults = <Predictive[]>[];
      }
    }

  }
  public getSearchMethod(searchText: string, numberOfResults: number): SearchModes {
    let result: SearchModes;
    if (this.thresholdMet(searchText)) {
      result = numberOfResults > 0 ? SearchModes.PredictivesFound : SearchModes.NoResultsFound;
    } else {
      result = numberOfResults > 0 ? SearchModes.RecentsFound : SearchModes.NoRecentResultsFound;
    }

    return result;
  }
  @IgpsDebounce(300)
  public onSearchInputChange(value: string, keyCode: number): void {
    switch (keyCode) {
      case 13: //enter
        this.respondToSearchRequest();
        break;
      case 27: //escpae
        this.escapeSearchAndReset();
        break;
      default:
        this.isThereSearchInput = value.length > 0;
        this.searchSubject.next(value);
        break;
    }
  }
  public initiateSearch(searchField: string): void {
    this.cancelSearch.next(true);
    this.cancelSearch.complete();
    this.recentSearched = false;
    this.SearchResultService.searchObject = this.resetSearchObject(searchField, this.selectedType);
    const queryParams = { queryParams: { search: searchField } };
    // this.fileNavigate(this.pathLinks.FileSearchResults, queryParams);
    this.SearchResultService.searchRequestedTime = new Date();
  }
  public resetSearchObject(searchText: string, searchType: string): SearchDescription {
    this.SearchResultService.resetSearchPagination();
    const result = {
      searchMethod: 'basic',
      searchText: searchText,
      searchType: searchType,
      hasResults: null,
      hasSelectedCategoryResults: null,
      resultCount: 0,
    };

    return <SearchDescription>result;
  }
  public respondToSearchRequest(): void {
    const searchField = this.searchInput.nativeElement.value;

    if (this.thresholdMet(searchField)) {
      this.initiateSearch(searchField);
      this.removeFocusFromInput();
      if (this.isEmailSearch) {
        this.router.navigateByUrl(`email-search/${this.selectedType}/${searchField}`, { state: { emailId: this.emailId } });
      }
      else {
        this.router.navigateByUrl(`${environment.appPath}/${this.selectedType}/${searchField}`);
      }
    }
    this.showSearchResultsDropdown = false;
    this.searchMode = SearchModes.Inactive;
  }

  public escapeSearchAndReset(): void {
    this.showSearchResultsDropdown = false;
    this.removeFocusFromInput();
  }

  public removeFocusFromInput(): void {
    this.searchInput.nativeElement.blur();
    this.searchMode = SearchModes.Inactive;
  }

  public clearSearchField(): void {
    if (this.searchInput) {
      this.searchInput.nativeElement.value = '';
    }
    this.onSearchInputChange('', 0);
  }

  public clearSearchFieldAndSearchRecents(): void {
    this.focusSearchField();
    this.clearSearchField();
  }

  public focusSearchField(): void {
    if (this.searchInput) {
      this.searchInput.nativeElement.focus();
    }
  }
  onBack() {
    this.navigate();
  }
  navigate() {
    if (this.userInfo.assumedRoleId == 1) {
      this.router.navigate(['manager/dashboard']);
    }
    else {
      this.router.navigate(['processor/dashboard']);
    }
  }
  ngOnDestroy(): void {
    if (this.subscribeObjectList.length > 0) {
      this.subscribeObjectList.forEach((s) => {
        if (s) {
          s.unsubscribe();
        }
      });
    }
  }
}



