export enum TransactionEnums {
  businessSegmentName = 'Business Segment',
  transactionType = 'Transaction Type',
  openDate = 'Open Date',
  estimatedSettlementDate = 'Est. Settlement Date',
  settlementDate = 'Settlement Date',
  prorateDate = 'Prorate Date',
  salesPrice = 'Sale Price',
  firstNewLoan = 'First New Loan',
  secondNewLoan = 'Second New Loan',
  thirdNewLoan = 'Third New Loan',
  fourthNewLoan = 'Fourth New Loan',
  fifthNewLoan = 'Fifth New Laon',
  seventhNewLoan = 'Seventh New Loan',
  eighthNewLoan = 'Eighth New Loan',
  ninethNewLoan = 'Nineth New Loan',
  businessPrograms = 'Business Programs',
  shortLegal = 'Short Legal',
}
