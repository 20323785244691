
export const statusList = [
    { id: 1, value: 'New' },
    { id: 2, value: "In Process" },
    { id: 3, value: 'Query' },
    { id: 4, value: 'Onshore' },
    { id: 5, value: 'On Hold' },
    { id: 6, value: 'Cancelled' },
    { id: 7, value: 'Branch' },
    { id: 8, value: 'Bot' },
    { id: 9, value: 'Completed With Exception' },
    { id: 777, value: 'Completed' },
    { id: 13, value: 'Spam' },
    { id: 11, value: 'Lost and Found' }
]