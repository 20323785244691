import { Component, EventEmitter, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { mountRootParcel } from 'single-spa';
import { Location } from '@angular/common';
import { getUserInfo, ws_sendMessage, wsStatus$, ipsWsMessages$ } from '@igps/client-root-config';
import { Router } from '@angular/router';
import { ActionItem } from 'src/app/models/actionItem';
import { EmailStatus, emailstatuses } from 'src/app/enums/emailStatus';
import { UtilityService } from 'src/app/global/services/utility.service';
import { showToastEvent, selectedTaskId, EmailMessageId, EmailTaskId } from '@igps/top-utility';
import { environment } from 'src/environments/environment';
import { Subscription, finalize, timer } from 'rxjs';
import { ActionService } from 'src/app/global/services/action.service';
import { EmailActionPopupComponent } from '../file-search/email-action-popup/email-action-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
export const refreshActions: EventEmitter<void> = new EventEmitter<void>();
import { MacrosResponse } from 'src/app/models/macros';
import { EmailModel } from 'src/app/global/models/EmailModel';
import { MoveEmailPopupComponent } from '../move-email-popup/move-email-popup.component';
import { emailDashConstant } from 'src/app/global/constants/email-search-constants';
import { Payload } from 'src/app/models/wsSendMessage.interface';
import { Events } from 'src/app/models/Events';
import { EmailStatusComponent } from '../email-status/email-status.component';
@Component({
  selector: 'app-email-search',
  templateUrl: './email-search.component.html',
  styleUrls: ['./email-search.component.scss']
})
export class EmailSearchComponent implements OnInit, OnDestroy {
  state!: any;
  emailId: any;
  userInfo!: any;
  emailProps = {}
  selected: "Select Action";
  mountRootParcel = mountRootParcel;
  selectedEmailStatusId: number | undefined;
  selectedEmailStatus: string;
  actionItem!: ActionItem[];
  emailAssignedTo!: string;
  enableAction: boolean = true;
  onShoreQueue: boolean = false;
  emailStatus!: string;
  RefreshActionSubscription!: Subscription;
  wsStatusSubscription!: Subscription;
  WebSockectSubscription!: Subscription;
  selectedAction: number | undefined;
  basicInfo: any;
  macro: MacrosResponse = { "MacroIds": [], "Note": "" };
  emailResponse!: EmailModel;
  public enableSearchBar: boolean = false;
  @ViewChild(EmailStatusComponent) refreshDD:EmailStatusComponent ;
  config(): Promise<any> {
    return (window as any).System.import(environment.emailParcel);
  }
  constructor(private location: Location, private router: Router, private utilityservice: UtilityService,
    private actionService: ActionService, private zone: NgZone, private matDialog: MatDialog) {
    this.state = this.location.getState();
    if (sessionStorage.getItem("AssigneeLoad")) {
      this.state = JSON.parse(sessionStorage.getItem("AssigneeLoad"));
    }
    this.emailId = this.state?.emailId ? this.state?.emailId : sessionStorage.getItem('selectedEmailNoteTaskId');
    this.selectedEmailStatusId = this.state?.emailStatusId;
    if (this.selectedEmailStatusId) {
      sessionStorage.setItem('emailStatusId', this.selectedEmailStatusId.toString())
    }
    this.emailAssignedTo = this.state?.assigneeId;
    if (this.emailAssignedTo) {
      sessionStorage.setItem('emailAssignedTo', this.emailAssignedTo)
    }
    this.onShoreQueue = this.state?.onShoreToggle;
    this.emailProps = { emailId: this.emailId };
    if (this.state.emailStatusId) {
      emailDashConstant.emailAssignedTo = this.emailAssignedTo;
      emailDashConstant.selectedEmailStatusId = this.selectedEmailStatusId;
      emailDashConstant.emailId = this.emailId;
      emailDashConstant.onShoreQueue = this.onShoreQueue;
    }
    else {
      this.emailAssignedTo = this.emailAssignedTo != undefined ? this.emailAssignedTo : sessionStorage.getItem('emailAssignedTo');
      this.emailId = emailDashConstant.emailId = this.emailId;
      this.selectedEmailStatusId = this.selectedEmailStatusId ? this.selectedEmailStatusId : parseFloat(sessionStorage.getItem('emailStatusId'))
      this.onShoreQueue = emailDashConstant.onShoreQueue;
    }
  }
  ngOnInit() {
    this.userInfo = getUserInfo();
    // selectedTaskId.emit(this.emailId);
    EmailMessageId.emit(this.emailId);
    this.loadActionsBasedOnEmailStatusAndRole();
    this.wsStatusSubscription = wsStatus$.subscribe((event: { status: string }) => {
      if (event.status.toUpperCase() === "CONNECTED") {
        this.sendwsMessage();
      }
    });
    this.WebSockectSubscription = ipsWsMessages$.subscribe(data => {
      if (data) {
        if (Events.TriggerEvents.includes(data?.EventName?.toLowerCase())) {
          selectedTaskId.emit(this.emailId);
          EmailMessageId.emit(this.emailId);
          this.getEmailDetails();
          if (data?.EventName?.toLowerCase() === 'emailmovedevent') {
            this.zone.run(() => {
              showToastEvent.emit({ message: "Email moved to other process.", isError: true });
              timer(3000).subscribe(() => this.navigate());
            });
          }
          if (data?.EventName?.toLowerCase() === 'emaillinkedtotaskevent' && this.router.url != '/email-search') {
            this.zone.run(() => {
              if (data.Payload.TaskId?.length > 0 && data.Payload.EmailMessageId?.length > 0) {
                EmailMessageId.emit(data.Payload.EmailMessageId);
                EmailTaskId.emit(data.Payload.TaskId);
                selectedTaskId.emit(data.Payload.EmailMessageId);
                sessionStorage.setItem("isReloadRequired", "true");
                this.router.navigate(['/process/task', data.Payload.TaskId, { emailId: data.Payload.EmailMessageId }], { state: { emailId: data.Payload.EmailMessageId } });
              }
            });
          }
        }
        else if (Events.NotesEvent.includes(data?.EventName?.toLowerCase()) || Events.DocumentEvent.includes(data?.EventName?.toLowerCase())) {
          selectedTaskId.emit(this.emailId);
          EmailMessageId.emit(this.emailId);
        }
      }
    });
  }

  getEmailDetails() {
    this.zone.run(() => {
      this.enableSearchBar = false;
      this.actionService.getEmailDetails(this.emailId).subscribe((emailData: EmailModel) => {
        this.emailResponse = emailData;
        emailDashConstant.emailAssignedTo = this.emailAssignedTo = emailData.assignedTo;
        emailDashConstant.selectedEmailStatusId = this.selectedEmailStatusId = emailData.status;
        emailDashConstant.onShoreQueue = this.onShoreQueue = emailData.onShoreQueue;
        sessionStorage.setItem('emailAssignedTo', emailData.assignedTo);
        sessionStorage.setItem('emailStatusId', emailData.status.toString());
        this.loadActionsBasedOnEmailStatusAndRole();
      });
    });
  }

  onBack() {
    this.navigate();
  }
  navigate() {
    if (this.userInfo.assumedRoleId == 1) {
      this.router.navigate(['manager/dashboard']);
    }
    else {
      this.router.navigate(['processor/dashboard']);
    }
  }

  getStatus(statusid: number): string {
    return emailstatuses.find((x: any) => x.id === statusid)!.displayText;
  }

  loadActionsBasedOnEmailStatusAndRole() {
    if (this.userInfo) {
      //if (this.userInfo.role.id === 1000) {
      if (!this.emailAssignedTo) {
        //   this.enableAction = true;
        //   this.enableSearchBar = true;
        // }
        // else {
        this.enableAction = false;
        showToastEvent.emit({ message: "Cannot take action on Unassigned Email.To take actions on this email, please assign it", isError: true });
        // }
      }
      else {
        if (!(this.selectedEmailStatusId === EmailStatus.COMPLETED || this.selectedEmailStatusId === EmailStatus.COMPLETED_WITH_EXCEPTION || this.selectedEmailStatusId === EmailStatus.BRANCH
          || this.selectedEmailStatusId === EmailStatus.WAIVED || this.selectedEmailStatusId === EmailStatus.CANCELLED)) {
          if (this.userInfo.role.id === 1000 && this.emailAssignedTo.length > 0) {
            this.enableAction = true;
            this.enableSearchBar = true;
          }
          else if (this.userInfo.role.id != 1000 && this.userInfo.id === this.emailAssignedTo) {
            this.enableAction = true;
            this.enableSearchBar = true;
          }
          else {
            this.enableAction = false;
            this.enableSearchBar = false;
            showToastEvent.emit({ message: "To take actions on this email, please assign it to yourself", isError: true });
          }
        }
        else if (this.selectedEmailStatusId === EmailStatus.COMPLETED || this.selectedEmailStatusId === EmailStatus.COMPLETED_WITH_EXCEPTION || this.selectedEmailStatusId === EmailStatus.BRANCH
          || this.selectedEmailStatusId === EmailStatus.WAIVED) {
          this.enableAction = false;
          this.enableSearchBar = false;
          this.selectedEmailStatus = this.getStatus(this.selectedEmailStatusId)
          showToastEvent.emit({ message: "Status " + this.selectedEmailStatus + ". No action can be taken", isError: true });
        }

        else if (this.selectedEmailStatusId === EmailStatus.CANCELLED) {
          this.enableAction = false;
          this.enableSearchBar = false;
          showToastEvent.emit({ message: "This task has been cancelled", isError: true });
        }
      }
      this.actionItem = [
        { value: 12, action: 'Mark as Spam' },
        { value: 5, action: 'Send to Query' },
        { value: 6, action: 'Put On Hold' },
        { value: 15, action: 'Move the Email message' },
        { value: 11, action: 'Lost and Found' },
      ];
      if (this.selectedEmailStatusId === EmailStatus.ON_HOLD) {
        if (this.userInfo.role.id === 1000 && this.emailAssignedTo.length > 0) {
          this.enableAction = true;
          this.enableSearchBar = true;
        }
        else if (this.userInfo.role.id != 1000 && this.userInfo.id === this.emailAssignedTo) {
          this.enableSearchBar = false;
          this.enableAction = true;
          showToastEvent.emit({ message: "Status is Hold. Remove Hold in order to search file and link to task", isError: true });
        }
        else {
          this.enableAction = false;
          this.enableSearchBar = false;
          showToastEvent.emit({ message: "To take actions on this email, please assign it to yourself", isError: true });
        }

        this.actionItem = [
          { value: 7, action: 'Remove Hold' }
        ];
      }
      else if (this.selectedEmailStatusId === EmailStatus.SPAM) {
        if (this.userInfo.role.id === 1000 && this.emailAssignedTo.length > 0) {
          this.enableAction = true;
          this.enableSearchBar = true;
        }
        else if (this.userInfo.role.id != 1000 && this.userInfo.id === this.emailAssignedTo) {
          this.enableSearchBar = false;
          this.enableAction = true;
          showToastEvent.emit({ message: "Status is Spam. Remove from Spam in order to search file and link to task", isError: true });
        } else {
          this.enableAction = false;
          this.enableSearchBar = false;
          showToastEvent.emit({ message: "To take actions on this email, please assign it to yourself", isError: true });
        }
        this.actionItem = [
          { value: 13, action: 'Remove Spam' }
        ];
      }
      else {
        if (this.userInfo.role.id === 1000) {
          if (this.onShoreQueue) {
            this.actionItem.push({ value: 8, action: 'Escalate to Onshore' });
          }
        }
        else if ((this.userInfo.role.id === 750 || this.userInfo.role.id === 500)) {
          if (this.onShoreQueue) {
            this.actionItem.push({ value: 8, action: 'Escalate to Onshore' });
          }
        }
        if (this.userInfo.assumedRoleId == 1) {
          this.actionItem.push({ value: 9, action: 'Send to Processor' });
        }
      }

      this.actionItem = this.actionItem.sort((a, b) => a.action.localeCompare(b.action));
    }
  }
  onActionClick(event) {
    if (event.value == 3 || event.value == 5 || event.value == 6 || event.value == 8 || event.value == 9 || event.value == 11 || event.value == 12) {
      this.zone.run(() => {
        const dialogRef = this.matDialog.open(EmailActionPopupComponent, {
          panelClass: ['ig2', 'mat-dialog-container-small'],
          data: {
            actionName: event.action,
            actionId: event.value,
            taskid: sessionStorage.getItem('selectedEmailNoteTaskId')?.toString(),
            processId: sessionStorage.getItem('ActiveProcessId')?.toString()
          },
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe(() => {
          this.selectedAction = undefined;
        });
      });
    }
    else if (event.value == 7 || event.value == 13) {
      const macro =
        this.zone.run(() => {
          this.utilityservice.setSpinner(true);
          this.actionService.submitAction(this.macro, sessionStorage.getItem('selectedEmailNoteTaskId')?.toString(), event.value).pipe(finalize(() => {
            this.utilityservice.setSpinner(false);
          }))
            .subscribe({
              next: (data: any) => {
                this.emailResponse = data;
                this.selectedEmailStatusId = this.emailResponse.status;
                showToastEvent.emit({ message: "Submitted successfully", isError: false });
                this.loadActionsBasedOnEmailStatusAndRole();
                selectedTaskId.emit(this.emailId);
                EmailMessageId.emit(this.emailId);
                this.refreshDD.getEmailDetails();
              },
              error: (error: HttpErrorResponse) => {
                if (error?.error.Status !== 409) {
                  this.utilityservice.showToast.emit({ message: 'Error submitting', isError: true });
                } else {
                  this.utilityservice.showToast.emit({ message: 'Error occurred', isError: true });
                }
              }
            });
        });

    }
    else if (event.value === 15) {
      this.zone.run(() => {
        const dialogRef = this.matDialog.open(MoveEmailPopupComponent, {
          panelClass: ['ig2'],
          width: '520px',
          height: '342px',
          data: {
            emailMessageId: this.emailId
          },
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(() => {
          this.selectedAction = undefined;
        });
      });
    }
  }
  private sendwsMessage() {
    const payload: Payload = {
      activePage: "emailmessage",
      processId: sessionStorage.getItem('ActiveProcessId')?.toString(),
      emailMessageId: this.emailId
    };
    ws_sendMessage(payload);
  }
  ngOnDestroy(): void {
    this.RefreshActionSubscription?.unsubscribe();
    this.wsStatusSubscription?.unsubscribe();
    this.WebSockectSubscription?.unsubscribe();
    sessionStorage.removeItem("AssigneeLoad");
  }
}
