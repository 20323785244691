import { Component, Input, OnInit } from '@angular/core';
import { FaIconsRegistry, faSpContactDefault, faSpContactPending, faSpContactSuccess } from '@ui-core/icon';
import { Subscription, tap } from 'rxjs';
import { BadgeType } from 'src/app/global/enums/badge-type.enum';
import { ContactType } from 'src/app/global/enums/contact-type.enum';
import { StatusState } from 'src/app/global/models/status-state';

@Component({
  selector: 'secure-portal-badge',
  templateUrl: './secure-portal-badge.component.html',
  styleUrls: ['./secure-portal-badge.component.scss']
})
export class SecurePortalBadgeComponent implements OnInit {
  public subscribeObjectList: Subscription[] = [];
  @Input() principalId: number;
  @Input() isPrimary?: boolean;
  @Input() contactId?: number;
  @Input() contactType: ContactType;

  public status: StatusState = <StatusState>{ badgeType: BadgeType.gray, status: 'Not Eligible' };
  public dataFetched = false;
  public badgeType = BadgeType.gray;

  constructor(private faIconsRegistry: FaIconsRegistry) {
    this.faIconsRegistry.registerIcons([faSpContactDefault, faSpContactPending, faSpContactSuccess]);
  }

  ngOnInit(): void {
    this.getData();
  }

  public getData(): void {
    if (this.principalId && this.dataFetched === false) {
      this.fetchStatus();
    }
  }

  public fetchStatus(): void {
    // const subscription = this.service
    //   .getSecurePortalStatus(this.contactType, this.principalId, this.isPrimary, this.contactId)
    //   .pipe(tap(() => (this.dataFetched = true)))
    //   .subscribe((result) => {
    //     this.status = result;
    //     this.service.deactivateSpinner();
    //   });
    // this.service.deactivateSpinner();
   // this.subscribeObjectList.push(subscription);
  }

  public ngOnDestroy(): void {
    if (this.subscribeObjectList.length > 0) {
      this.subscribeObjectList.forEach((s) => {
        if (s) {
          s.unsubscribe();
        }
      });
    }
  }
}
