import { Component, Input } from '@angular/core';
import { InfoCard } from '@ui-core/info-card';
import { FileInfoCard } from 'src/app/global/models/file-info-card';
import { SubscriptionLike as ISubscription } from 'rxjs';

@Component({
  selector: 'app-file-info-card',
  templateUrl: './file-info-card.component.html',
  styleUrls: ['./file-info-card.component.scss']
})
export class FileInfoCardComponent {
 // @Input() isFromDashboard: boolean;
  @Input() header = '';
  @Input() fileId: number;
  @Input() fileNumber = '';
  @Input() owningOfficeId: number;
  @Input() address = '';
  @Input() transactionDetails: InfoCard[] = [];
  @Input() transactionTeams: InfoCard[] = [];
  @Input() partyDetails: FileInfoCard[] = [];
  @Input() transactionContact: InfoCard[] = [];
  //@Input() hasAccess: boolean;

  public selectedIndex: number;
  public toggleArrow = {};
  public toggleContactInfo = false;
  public subscribeObjectList: ISubscription[] = [];
  public additionalInfoCheck = false;
  public additionalInfoIndex: number;
  public additionalInfoEntityName: string;
  public newPartyDetails: any;

  constructor() {}
  public additionalInfoClick(index: number): void {
    this.additionalInfoCheck = true;
    this.additionalInfoIndex = index;
    this.extractEntityName();
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public extractEntityName(): void {
    if (this.partyDetails[this.additionalInfoIndex].itemName === 'Buyer' || this.partyDetails[this.additionalInfoIndex].itemName === 'Seller') {
      if (this.partyDetails[this.additionalInfoIndex].itemObject.templateType === 'individual') {
        this.additionalInfoEntityName = this.partyDetails[this.additionalInfoIndex]?.itemObject?.contacts[0]?.contactFullName;
      }
      if (this.partyDetails[this.additionalInfoIndex].itemObject.templateType === 'husbandWife') {
        const spouse1Name = this.partyDetails[this.additionalInfoIndex]?.itemObject?.contacts[0]?.contactName;
        const spouse2Name = this.partyDetails[this.additionalInfoIndex]?.itemObject?.contacts[1]?.contactName;
        // if (CommonService.isTruthyValue(spouse1Name) && CommonService.isTruthyValue(spouse2Name)) {
        //   this.additionalInfoEntityName = `${spouse1Name}/${spouse2Name}`;
        // } else if (CommonService.isTruthyValue(spouse2Name)) {
        //   this.additionalInfoEntityName = spouse2Name;
        // } else {
        //   this.additionalInfoEntityName = spouse1Name;
        // }
      }
      if (this.partyDetails[this.additionalInfoIndex].itemObject.templateType === 'businessTrust') {
        const partyItemValueSplit = this.partyDetails[this.additionalInfoIndex].itemValue.split('<div class="contact__wrapper">');
        this.additionalInfoEntityName = partyItemValueSplit[0].substring(29, partyItemValueSplit[0].indexOf('</div>'));
      }
      if (this.partyDetails[this.additionalInfoIndex].itemObject.templateType === 'orgNoContactName') {
        const partyItemValueSplit = this.partyDetails[this.additionalInfoIndex].itemValue.split('<div class="contact__wrapper">');
        this.additionalInfoEntityName = partyItemValueSplit[1].substring(0, partyItemValueSplit[1].indexOf('</div>'));
      }
    } else {
      const partyItemValueSplit = this.partyDetails[this.additionalInfoIndex].itemValue.split('<div class="contact__wrapper">');
      this.additionalInfoEntityName = partyItemValueSplit[1].substring(0, partyItemValueSplit[1].indexOf('</div>'));
    }
  }
    public backArrow(): void {
    this.additionalInfoCheck = false;
  }
}
