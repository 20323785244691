<div class="main-div">
    <div class="flex-row">
        <div class="back-icon">
          <lib-icon name="arrow_left" size="standard" (click)="onBack()"></lib-icon>
        </div>
        <div class="main-header">
          <div class="top">{{processName}}</div>
        </div>
      </div>
</div>

<div class="email-status-container display-flex">
    <form [formGroup]="emailInfoForm" *ngIf="emailInfoForm" autocomplete="off" novalidate>
        <mat-form-field [id]="'status-'+emailInfoForm.controls['fcStatus'].value" class="ig2 mat-form-field-no-animation " appearance="fill">
            <mat-label id="status-label" class="mat-label-wrap">Email Status</mat-label>
            <mat-select class="ig2" placeholder="--Select--" formControlName="fcStatus">
                <mat-option [value]="item.id" *ngFor="let item of statusList">
                    {{item?.value}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field id="due-date" class="ig2 mat-form-field-no-animation mat-form-field-datepicker" appearance="fill">
            <mat-label>Due Date
                <button class="ig2 mat-button-tooltip" mat-icon-button type="button" matTooltipClass="ng-trigger" [matTooltip]="'Date and time in ' + timezone.name + ' (' + timezone.abbreviation +'), within which the task is expected to be completed'" aria-label="Button that displays a tooltip when focused or hovered over">
                    <img class="mat-button-tooltip-icon" src="/assets/icons/icon-question-dark.svg" alt="question icon" />
                </button>
            </mat-label>
            <input class="mat-input-element-show-placeholder" matInput [min]="minDate" [max]="maxDate" [matDatepicker]="tatDatePicker" placeholder="Select a date" formControlName="fcTatDate"  />
            <mat-datepicker-toggle class="ig2" matSuffix [for]="tatDatePicker">
                <mat-icon matDatepickerToggleIcon (click)="tatDatePicker.open()">
                    <img src="/assets/icons/icon-calendar-dark.svg" alt="calendar icon" />
                </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker panelClass="ig2" #tatDatePicker></mat-datepicker>
        </mat-form-field>

        <app-timepicker  [disabledStatus]="disabledTimeControl" [selectedTime]="selecedTime" [timeFormat]="timeFormat"></app-timepicker>

        <div id="Assigneedropdown">
            <reactive-filter-select id="Assigneedropdown" class="ig2" [options]="staffList" label="Assigned To" placeholder="Select Assignee" (searchChange)="search.emit($event)" searchPlaceholder="Search Assignee" [sort]="false" noMatchFoundText="No User Found." [(searchText)]="searchText" [(isLoading)]="isUsersLoading" formControlName="fcAssignedTo" (selectionChange)="onAssignToValueChange($event)" (openedChange)="onOpenChangeAssignee()" [forceShowSearch]="true" [isAPIBind]="true" [setValueEvent]="setAssigneeValueEvent">
                <ng-template let-item #option>
                    <div *ngIf="item.id != 'null'">
                        <div> {{item.name}} </div>
                        <div class="sub-label"> <span>{{item.role}}</span>, <span>{{item.site}}</span> </div>
                    </div>
                    <span *ngIf="item.id == 'null'">{{item.name}}</span>
                </ng-template>
            </reactive-filter-select>
        </div>

    </form>
</div>