import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
// import { IgniteMessage2Component } from '../components/ignite-message2/ignite-message2.component';
// import { IgniteAlertDialogComponent } from '../components/ignite-alert-dialog/ignite-alert-dialog.component';


export enum CommonMessage {
  MandatoryMessage = 'You must enter a value.',
  ChangesNotSavedMessage = 'Changes will not be saved.',
  EnterValidDataMessage = 'Please enter valid data.',
  UnauthroizeMessage = 'You are not authorized to access admin.',
  ConcurrentMessage = 'Multiple users are viewing this file.',
  RepublishMessage = 'Admin has made updates to your File Checklist, the updates will be added after the refresh.',
  NewChecklistAvailabeMessage = 'We have successfully found a better checklist for the changes to the file.',
  ConfirmReplaceChecklistMessage = 'Replacing the CheckList will clear out all of the current tasks and pull in a new set.',
  VerifyingChecklist = 'We are determining the best checklist for the changes you have made.',
  ExceptionMessage = 'Server operation failed. Please re-try or report the incident if the issue persists.',
  // tslint:disable-next-line:max-line-length
  EclipseUnavailableMessage = 'Eclipse Service is unavailable. You may close the application and open again. Please report an incident if the issue still persists.',
  AccessDenied = 'You do not have an access to this feature.',
  InsufficientRights = 'Insufficient activity rights! Please contact admin.',
  NavigateWithUnSavedChanges = 'You may have unsaved changes. Do you wish to continue?',
  DeleteAppointmentMessage = 'Clicking OK will delete this appointment.',
  DeletedDocumentMessage = 'You no longer have the ability to view this document as it has been removed in FAST.',
  DeleteFilterMessage = 'Do you wish to delete the selected filter?',
  DeleteFilterMessageV2 = 'Are you sure you would like to delete this filter?',
  DeleteWidgetMessage = 'Do you wish to delete the selected widget?',
  SignalRRetryConnection = 'Ignite is experiencing a temporary disconnection from FAST. Attempting to automatically reconnect...',
  SignalRConnectionDisconnected = 'Ignite has disconnected from FAST. Any actions taken from this point forward will not sync with FAST. You must close Ignite and relaunch to reconnect.',
  DocumentUnAuthorizeMessage = '<b>Access Denied.</b> You do not have permission to view this document.',
  UnsavedChangesMessage = 'You have unsaved work that has not been submitted. Would you like to continue?',
  NewChecklistAvailabeMessage2 = 'A new checklist template is available',
  WarrningReplaceChecklistMessage = 'A new checklist template is available. Current changes may be lost if you decide to replace. Do you want to replace the old one with the new checklist template?',
  ReplaceConfirmButton = 'Yes, Replace',
  KeepChecklistButton = 'No, Keep',
  ChecklistNotAvailableMessage = 'There is no available checklist for this file.',
  ConcurrencyExceptionMessage = 'Concurency exception. Please re-try or report the incident if the issue persists.',
  InternalServerErrorMessage = 'Internal server error. Please re-try or report the incident if the issue persists.',
  EclipseServerErrorMessage = 'Internal server error in Eclipse service. Please re-try or report the incident if the issue persists.',
  BadRequestMessage = 'Bad Request. Please re-try or report the incident if the issue persists.',
  DuplicateOrInvalidExceptionMessage = 'Duplicate Or Invalid Exception. Please re-try or report the incident if the issue persists.',
  UnauthorizedMessage = 'Unauthorized access. Please contact admin',
  FileIsNotAvailable = 'File is not available',
  HOARushWaringMessage = 'A rush could result in rush fee. Do you approve?',
  NoDonotRushButton = "No, Don't Rush",
  YesApproveButton = 'Yes, Approve',
  PortalMessageTriggerWarningMessage = 'Must have at least one row of source details to save the Trigger',
}

export const APP_DATE_FORMATS = {
  parse: {
    dateInput: 'MM-DD-YYYY',
  },
  display: {
    dateInput: 'MM-DD-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const DUE_DATE_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private btnAlert = ['Ok'];
  private btnLables = ['Ok', 'Cancel'];
  apiUrl: string;

  constructor(private router: Router, private matDialog: MatDialog, private http: HttpClient) {}

  public pendingWarningHtml = `<div class="warning-popup">
        <div class="row">
        <div class="col-10" style="color: #4043cc">Warning!</div>
        </div><hr>
        <div class="row">
        <div class="col-12" style="margin-top: 10pxmargin-bottom: 10px">
        You have unsaved work that has not been submitted.</div>
        </div><div class="row">
        <div class="col-12" style="margin-bottom: 10px">
        Would you like to continue?</div></div><hr></div>`;

  public static isTruthyValue(val): boolean {
    if (val === undefined || val === null) {
      return false;
    }
    if (Object.prototype.toString.call(val) === '[object String]') {
      val = val.trim();
    }
    if (val === '') {
      return false;
    }
    if (typeof val !== 'object' && typeof val !== 'function') {
      if (val === 'False' || val === 'false' || val === 'FALSE') {
        return false;
      }
    }
    return true;
  }

  public static isJsonString(value): boolean {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  }

  // public openWarningDialog(eMessage, labels) {
  //   return this.matDialog.open(IgniteAlertDialogComponent, {
  //     width: '450px',
  //     data: { dMessage: eMessage, dLabels: labels },
  //     disableClose: true,
  //   });
  // }

  // public openWarningDialog2(wMessage: string, header = 'Warning', labels = ['No', 'Yes']) {
  //   return this.matDialog.open(IgniteMessage2Component, {
  //     panelClass: ['ig2', 'mat-dialog-container-small'],
  //     data: {
  //       heading: header,
  //       message: wMessage,
  //       labels: labels,
  //       size: 'small',
  //       align: 'end',
  //     },
  //     disableClose: true,
  //   });
  // }


  public customDateFormat(dueDate: any) {
    const date = new Date(dueDate);
    const weekdays: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    // tslint:disable-next-line:max-line-length
    const months: string[] = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const month = months[date.getMonth()];

    const weekday = weekdays[date.getDay()];
    const day = date.getDate();
    const year = date.getFullYear();
    return `${weekday}, ${month} ${day}, ${year}`;
  }

  public parseIntegerValue(n: number | string): number {
    return typeof n === 'number' ? n : parseInt(n, 10);
  }
}
