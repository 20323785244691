import { Injectable } from '@angular/core';
import { MacroModel, MacroSubCategory, MacrosResponse, ProductionProcess } from 'src/app/models/macros';
import { map, merge, mergeMap, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Assignee, TaskBasicInfo } from 'src/app/models/emailBasicInfo';
import { EmailActionPopupComponent } from 'src/app/components/file-search/email-action-popup/email-action-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { EmailModel, MoveEmailResponse } from '../models/EmailModel';

@Injectable({
  providedIn: 'root'
})
export class ActionService {

  constructor(private httpClient: HttpClient, private matDialog: MatDialog) { }
  readonly domainUrl = `${environment.igps.apiUrl}`;
  readonly baseUrl = `${environment.igpsBase.apiUrl}`;
  readonly emailUrl = `${environment.igpsBaseUrl.emailUrl}`;
  readonly IGPSBaseUrl = `${environment.igpsBaseUrl.apiUrl}`;

  getMacroSubCategory(actionId: number): Observable<MacroSubCategory[]> {
    const filters: { Field: string; Value: string }[] = [
      {
        Field: "Status.Id",
        Value: "1 OR 2 OR 3 OR 4"
      },
      {
        Field: "ActionId",
        Value: actionId.toString()
      }
    ];
    return this.httpClient.post<{
      count: number;
      items: MacroSubCategory[];
      pageNumber: number;
      pageSize: number;
      totalItemCount: number;
      totalPageCount: number;
    }>(`${this.domainUrl}search/macrosubcategory/v1/dropdownsearch`, {
      PageRequest: {
        PageNumber: 1,
        PageSize: 50,
      },
      SortRequest: {
        Field: 'Name.keyword',
        Direction: 1,
      },
      Filters: filters,
    }).pipe(
      map(resp => {
        if (resp.pageSize < resp.totalItemCount) {
          let observables: Observable<MacroSubCategory[]>[] = [];
          observables.push(of(resp.items));
          const noofapicalls: number = (Math.ceil(resp.totalItemCount / resp.pageSize) - 1);
          for (let index = 1; index <= noofapicalls; index++) {
            observables.push(this.getMacroSubCategories(index + 1, resp.pageSize, filters));
          }
          return merge(...observables);
        }
        return of(resp.items);
      }),
      mergeMap(d => d)
    );
  }

  private getMacroSubCategories(pageno: number, pagesize: number, filters: { Field: string; Value: string }[]): Observable<MacroSubCategory[]> {
    return this.httpClient.post<{
      count: number;
      items: MacroSubCategory[];
      pageNumber: number;
      pageSize: number;
      totalItemCount: number;
      totalPageCount: number;
    }>(`${this.domainUrl}search/macrosubcategory/v1/dropdownsearch`, {
      PageRequest: {
        PageNumber: pageno,
        PageSize: pagesize,
      },
      SortRequest: {
        Field: 'Name.keyword',
        Direction: 1,
      },
      Filters: filters,
    }).pipe(
      map(resp => resp.items)
    );
  }

  getMacro(actionId: number, productionProcessId: string, isOnshore: boolean, isOffshore: boolean): Observable<MacroModel[]> {
    const filters: { Field: string; Value: string }[] = [
      {
        Field: "Status.Id",
        Value: "1"
      },
      {
        Field: "ProcessList.ProcessId.keyword",
        Value: productionProcessId
      },
      {
        Field: "ActionId",
        Value: actionId.toString()
      }
    ];
    if (isOnshore) {
      filters.push({
        Field: "HasOnshoreQueue",
        Value: String(isOnshore)
      });
    }
    else {
      filters.push({
        Field: "HasOffshoreQueue",
        Value: String(isOffshore)
      });
    }
    return this.httpClient.post<{
      count: number;
      items: MacroModel[];
      pageNumber: number;
      pageSize: number;
      totalItemCount: number;
      totalPageCount: number;
    }>(`${this.domainUrl}search/macro/v1/clientdropdownsearch`, {
      PageRequest: {
        PageNumber: 1,
        PageSize: 20,
      },
      SortRequest: {
        Field: 'MacroName.keyword',
        Direction: 1,
      },
      Filters: filters,
    }).pipe(
      map(resp => {
        if (resp.pageSize < resp.totalItemCount) {
          let observables: Observable<MacroModel[]>[] = [];
          observables.push(of(resp.items));
          const noofapicalls: number = (Math.ceil(resp.totalItemCount / resp.pageSize) - 1);
          for (let index = 1; index <= noofapicalls; index++) {
            observables.push(this.getMacros(index + 1, resp.pageSize, filters));
          }
          return merge(...observables);
        }
        return of(resp.items);
      }),
      mergeMap(d => d)
    );
  }

  private getMacros(pageno: number, pagesize: number, filters: { Field: string; Value: string }[]): Observable<MacroModel[]> {
    return this.httpClient.post<{
      count: number;
      items: MacroModel[];
      pageNumber: number;
      pageSize: number;
      totalItemCount: number;
      totalPageCount: number;
    }>(`${this.domainUrl}search/macro/v1/clientdropdownsearch`, {
      PageRequest: {
        PageNumber: pageno,
        PageSize: pagesize,
      },
      SortRequest: {
        Field: 'MacroName.keyword',
        Direction: 1,
      },
      Filters: filters,
    }).pipe(
      map(resp => resp.items)
    );
  }

  getProductionProcess(productionSystemId: string): Observable<ProductionProcess[]> {
    const filters: { Field: string; Value: string }[] = [
      {
        Field: "Status.Id",
        Value: "1"
      },
      {
        Field: "ProductionSystemId.keyword",
        Value: productionSystemId
      },
      {
        Field: "TaskSource",
        Value: "2"
      }
    ];
    return this.httpClient.post<{
      count: number;
      items: ProductionProcess[];
      pageNumber: number;
      pageSize: number;
      totalItemCount: number;
      totalPageCount: number;
    }>(`${this.domainUrl}search/prdprocess/v1/dropdownsearch`, {
      PageRequest: {
        PageNumber: 1,
        PageSize: 20,
      },
      SortRequest: {
        Field: 'Name.keyword',
        Direction: 1,
      },
      Filters: filters,
    }).pipe(
      map(resp => {
        if (resp.pageSize < resp.totalItemCount) {
          let observables: Observable<ProductionProcess[]>[] = [];
          observables.push(of(resp.items));
          const noofapicalls: number = (Math.ceil(resp.totalItemCount / resp.pageSize) - 1);
          for (let index = 1; index <= noofapicalls; index++) {
            observables.push(this.getProductionProcesses(index + 1, resp.pageSize, filters));
          }
          return merge(...observables);
        }
        return of(resp.items);
      }),
      mergeMap(d => d)
    );
  }

  private getProductionProcesses(pageno: number, pagesize: number, filters: { Field: string; Value: string }[]): Observable<ProductionProcess[]> {
    return this.httpClient.post<{
      count: number;
      items: ProductionProcess[];
      pageNumber: number;
      pageSize: number;
      totalItemCount: number;
      totalPageCount: number;
    }>(`${this.domainUrl}search/prdprocess/v1/dropdownsearch`, {
      PageRequest: {
        PageNumber: pageno,
        PageSize: pagesize,
      },
      SortRequest: {
        Field: 'Name.keyword',
        Direction: 1,
      },
      Filters: filters,
    }).pipe(
      map(resp => resp.items)
    );
  }

  getEmailDetails(emailMessageId: string): Observable<EmailModel>{
    return this.httpClient.get<EmailModel>(`${this.emailUrl}/emaildetail/${emailMessageId}`);
  }

  getTaskInformation(taskId: string) {
    return this.httpClient.get(`${this.baseUrl}` + `gentask/gentask/v1/task/` + `${taskId}` + `/basicinfo`);
  }

  getStaffNames(processId: string) {
    return this.httpClient.get(`${this.IGPSBaseUrl}` + `/staffing/staff/v1/process/` + `${processId}` + `/dropdown`)
  }

  submitAction(macroItem: MacrosResponse, emailMessageId: string, actionid: number): Observable<EmailModel> {
    return this.httpClient.post<EmailModel>(`${this.emailUrl}` + `/email/` + `${emailMessageId}` + '/action/' + `${actionid}`, macroItem);
  }

  moveEmailsubmitAction(emailMessageId: string, processId: string): Observable<MoveEmailResponse> {
    return this.httpClient.post<MoveEmailResponse>(`${this.emailUrl}/email/${emailMessageId}/moveemail`, { processId });
  }

  UnAssignEmail(emailId: string) {
    return this.httpClient.post(`${this.emailUrl}/email/${emailId}/unassign`, null);
  }

  ReAssignEmail(emailId: string, assigneeId: string) {
    return this.httpClient.post(`${this.emailUrl}/email/${emailId}/staff/${assigneeId}`, null);
  }

  getAssigneeOnSearch(prdsysId: string, searchText: string): Observable<Assignee[]> {
    const body = {
      PageRequest: {
        PageNumber: 1,
        PageSize: 50
      },
      Filters: [
        {
          Field: "FirstName LastName",
          Value: searchText
        }
      ]
    };
    const url = `${this.IGPSBaseUrl}/domaindatasearch/staff/v1/user/predictivesearch/prdsys/${prdsysId}`;
    return this.httpClient.post<{
      totalItemCount: number,
      totalPageCount: number,
      pageNumber: number,
      count: number,
      pageSize: number,
      items: Assignee[],
    }>(encodeURI(url), body).pipe(
      map((data: any) => {
        return data.items;
      })
    );
  }
  public openActionDialog(selectedAction: string, actionId: number, taskInfo: TaskBasicInfo, processId: string) {
    return this.matDialog.open(EmailActionPopupComponent, {
      panelClass: ['ig2', 'mat-dialog-container-small'],
      data: {
        actionName: selectedAction,
        actionId: actionId,
        taskInfo: taskInfo,
        processId: processId
      },
      disableClose: true,
    });
  }

}
