import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { FileSearchService } from "src/app/global/services/file-search.service";
import { UtilityService } from "src/app/global/services/utility.service";
import { ListDataModel } from "src/app/models/listingDataModal";


@Component({
    selector: 'app-view-task',
    templateUrl: './view-task-popup.component.html',
    styleUrls: ['./view-task-popup.component.scss']
})
export class ViewTaskPopupComponent implements OnInit {
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    dataModel = new ListDataModel();
    displayedColumns = ['orderDate'];
    dataSource = new MatTableDataSource<ListDataModel>();
    selectedRowIndex: any;
    selectedTaskDetail: any = null;
    showViewTemplate: boolean = false;
    noteList!: any;
    noteFilerList: [];
    showSpinner: boolean = false;
    gridSpinnerSubscription!: Subscription;
    totalPageCount: any;
    pageSize: any;
    currentPage: number = 1;
    constructor(public dialogRef: MatDialogRef<ViewTaskPopupComponent>, private route: Router,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            processId: string;
            processname: string;
            psName: string
            searchtext: string
            fastfileId: any,
            sourceType: any
            assigneeId: any
        },
        private searchService: FileSearchService,
        private utilityservice: UtilityService,
    ) {

    }
    ngOnInit(): void {
        this.utilityservice.setSpinner(true);
        this.dataSource.data = [];
        this.searchService.getAllTaskList(this.data.processId, this.data.fastfileId).subscribe((res: any) => {
            if (res)
                this.utilityservice.setSpinner(false);
            this.dataSource.data = res;
            if (this.dataSource.data && this.dataSource.data.length == 1) {
                this.highlight(this.dataSource.data[0])
            }
        }, err => {
            this.utilityservice.setSpinner(false)
        })
    }

    getCssName(x: any) {
        if (x == 'Completed w/ Exception') {
            return x = 'CompletedwException';
        } else {
            return x = x.replace(/\s+/g, '');
        }

    }
    processTask() {
        if (this.selectedTaskDetail && this.selectedTaskDetail.id) {
            if (this.selectedTaskDetail.status == 'New' && this.selectedTaskDetail.assigneeId == null) {
                this.searchService.Assignee(this.selectedTaskDetail.id, this.data.assigneeId).subscribe((res) => {
                    this.route.navigate(['/process/task', this.selectedTaskDetail.id]);
                }, err => {
                    this.route.navigate(['/process/task', this.selectedTaskDetail.id]);
                })
            } else {
                this.route.navigate(['/process/task', this.selectedTaskDetail.id]);
            }
        }
    }
    highlight(row: any) {
        if (this.data.sourceType == 2) {
            if (row && !row.isEmailLink && row.status == 'New') {
                this.selectedRowIndex = row.id;
                this.selectedTaskDetail = row;
            }
            else {
                this.selectedTaskDetail = null;
                this.selectedRowIndex = null
            }
        }
        else if (this.data.sourceType == 1) {
            this.selectedRowIndex = row.id;
            this.selectedTaskDetail = row;
        }
        else {
            this.selectedTaskDetail = null;
            this.selectedRowIndex = null
        }
    }
    viewNotes(row: any) {
        this.utilityservice.setSpinner(true);
        this.noteList = [];
        this.showViewTemplate = true;
        let id = row.emailId != null ? row.emailId : row.id;
        this.searchService.getNotesdetails(id).subscribe((res: any) => {
            this.utilityservice.setSpinner(false);
            res.forEach((x: any) => {
                if (x.updatedDate !== null) {
                    x.tempDate = x.updatedDate;
                }
                else {
                    x.tempDate = x.createdDate;
                }

            });
            res.sort(function (x, y) {
                return <any>new Date(y.tempDate) - <any>new Date(x.tempDate);
            });
            res = res.map(r => {
                return { ...r, isImageValid: true }
            })
            this.noteList = res;
            this.noteFilerList = res;


        },
            err => {
                this.utilityservice.setSpinner(false);
                this.noteList = null;
            })
    }

    viewBack() {
        this.showViewTemplate = false;
    }
    addNewTask() {
        this.utilityservice.setSpinner(true);
        this.searchService.createNewTask(this.data.processId, this.data.fastfileId).subscribe((res: any) => {
            if (res) {
                this.utilityservice.setSpinner(false);
                this.route.navigate(['/process/task', res.id])
            }
        }, err => {
            this.utilityservice.setSpinner(false);
        })
    }
    onPageClick(pageNumber: any) {
        this.utilityservice.setSpinner(true);
        let data = { "PageRequest": { "PageNumber": 0, "PageSize": 10 }, "SortRequest": null, "Filters": [] }
        this.currentPage = data.PageRequest.PageNumber = pageNumber;

        this.searchService.getAllTaskList(this.data.processId, this.data.fastfileId).subscribe((res: any) => {
            if (res)
                this.utilityservice.setSpinner(false);
            this.dataSource.data = res;
        }, err => {
            this.utilityservice.setSpinner(false)
        })

    }
    LinkprocessTaskEmail() {
        let emailId = sessionStorage.getItem('selectedEmailNoteTaskId')
        if (emailId && this.selectedTaskDetail && this.selectedTaskDetail.id) {
            this.searchService.LinkEmailtoExisting(emailId, this.selectedTaskDetail.id).subscribe(res => {
                if (res) {
                    sessionStorage.setItem("isReloadRequired", "true");
                    this.route.navigate(['/process/task', this.selectedTaskDetail.id, { emailId: emailId }], { state: { emailId: emailId } });
                }
            })
        }
    }
    LinkNewTaskEmail() {
        let emailId = sessionStorage.getItem('selectedEmailNoteTaskId')
        this.searchService.linkNewTaskEmail(emailId, this.data.fastfileId).subscribe((res: any) => {
            if (res) {
                sessionStorage.setItem("isReloadRequired", "true");
                this.route.navigate(['/process/task', res.taskId, { emailId: emailId }], { state: { emailId: emailId } });
            }
        })
    }
}