<app-file-info-card   
    [header]="header"
    [fileNumber]="fileNumber"
    [fileId]="fileId"
    [address]="address"
    [transactionDetails]="transactionDetails"
    [transactionTeams]="transactionTeams"
    [partyDetails]="partyDetails"  
></app-file-info-card>
<igps-lib-spinner class="spinner" [showSpinner]="showSpinner"></igps-lib-spinner>

