<div class="wrapper">   
    <div>
        <app-email-status [emailId]="emailId" #refreshDD ></app-email-status>
    </div> 
        <div [ngClass]="{'disableDiv': !enableSearchBar}"><app-file-search [emailId]="emailId" [isEmailSearch]="true"></app-file-search></div>
        <div>
            <parcel [customProps]="emailProps" [config]="config" [mountParcel]="mountRootParcel"></parcel>
        </div>
        <div>
            <div class="email-action">
                Select Email Action
            </div>
            <div class="label-input-labelwrapper">
                <mat-form-field class="ig2 mat-form-field-no-animation" appearance="fill">
                    <mat-select class="ig2" [disabled]="!enableAction" [(ngModel)]="selectedAction"
                        placeholder="Select Action">
                        <mat-option *ngFor="let item of actionItem" [value]="item.value" #matOption
                            (click)="onActionClick(item)"> {{item.action}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <span (click)="onBack()" class="search-container-email__back-btn">
                <svg viewBox="0 0 24 24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="m4.14 12.374 4.943 5.189a.49.49 0 0 0 .542.105.538.538 0 0 0 .164-.847L5.7 12.528h13.83a.47.47 0 0 0 .341-.159.52.52 0 0 0 .134-.366.535.535 0 0 0-.134-.365.487.487 0 0 0-.341-.159H5.699l4.09-4.293a.527.527 0 0 0 .147-.37.547.547 0 0 0-.146-.371.488.488 0 0 0-.707-.001l-4.944 5.19a.534.534 0 0 0-.144.326v.089c.01.12.058.236.144.326l.001-.001Z"
                        fill="#0044E9"></path>
                </svg>
                <span (click)="onBack()" class="text">Return to Dashboard</span>
            </span>
        </div>  
</div>