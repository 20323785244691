// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* You can add global styles to this file, and also import other style files */
.ig2.mat-dialog-container-small .mat-dialog-container {
  width: 100% !important;
}
.ig2.mat-dialog-container-small .mat-dialog-container .mat-dialog-title {
  padding-right: 20px !important;
}
.ig2.mat-dialog-container-small .mat-dialog-container .mat-dialog-content {
  overflow: visible !important;
  min-height: 50vh !important;
}`, "",{"version":3,"sources":["webpack://./src/styles.scss"],"names":[],"mappings":"AAAA,8EAAA;AACA;EACI,sBAAA;AACJ;AAEI;EACI,8BAAA;AAAR;AAGI;EACI,4BAAA;EACA,2BAAA;AADR","sourcesContent":["/* You can add global styles to this file, and also import other style files */\n.ig2.mat-dialog-container-small .mat-dialog-container {\n    width: 100% !important;\n\n\n    .mat-dialog-title {\n        padding-right: 20px !important;\n    }\n\n    .mat-dialog-content {\n        overflow: visible !important;\n        min-height: 50vh !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
