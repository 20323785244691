export enum EmailStatus {
    NEW = 1,
    IN_PROCEESS = 2,
    ON_QUERY = 3,
    ONSHORE = 4,
    ON_HOLD = 5,
    CANCELLED = 6,
    BRANCH = 7,
    BOT = 8,
    COMPLETED_WITH_EXCEPTION = 9,
    PENDING = 10,
    COMPLETED = 777,
    LOST_AND_FOUND = 11,
    WAIVED = 12,
    SPAM = 13
}


export const emailstatuses  = [
    { id : 1, name : 'NEW' , displayText:'New'},
    { id : 2, name : 'IN_PROCEESS' , displayText:'In Proceess'},
    { id : 3, name : 'ON_QUERY' , displayText:'Query'},
    { id : 4, name : 'ONSHORE' , displayText:'Onshore'},
    { id : 5, name : 'ON_HOLD' , displayText:'On Hold'},
    { id : 6, name : 'CANCELLED' , displayText:'Cancelled'},
    { id : 7, name : 'BRANCH' , displayText:'Branch'},
    { id : 8, name : 'BOT' , displayText:'Bot'},
    { id : 9, name : 'COMPLETED_WITH_EXCEPTION' , displayText:'Completed w/ Exception'},
    { id : 10, name : 'PENDING' , displayText:'Pending'},
    { id : 777, name : 'COMPLETED' , displayText:'Completed'},
    { id : 11, name : 'LOST_AND_FOUND' , displayText:'Lost and Found'},
    { id : 12, name : 'WAIVED' , displayText:'Waived'}
]