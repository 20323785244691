import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, SubscriptionLike as ISubscription } from 'rxjs';
import { DisplayDetailDto } from '../models/transaction-details-dto/display-detail-dto';
import { DisplayTeamDto } from '../models/transaction-details-dto/display-team-dto';
import { TransactionPreviewDto } from '../models/transaction-details-dto/transaction-preview-dto';
import { PartiesDto } from '../models/transaction-parties-dto/parties-dto';
import { CommonService } from './common.service';
import { FileSearchService } from './file-search.service';
import { TransactionInfoHeaderDto } from '../models/transaction-info-header-dto';
import { TeamEnums } from '../enums/team.enum';
import { TransactionEnums } from '../enums/transaction.enum';



@Injectable({
  providedIn: 'root',
})
export class TransactionInfoTabService {
  public searchText: string;
  public fileNo: string;
  public fileId: number;
  public header = '';
  public fileNumber: string;
  public address = '';
  public transactionPreview: TransactionPreviewDto;
  public transactionPartiesDetail: PartiesDto;
  public transactionDetails = [];
  public transactionTeams = [];
  public partyDetails = [];
  public transactionDetail;
  public transactionTeam;
  public transactionEnums = TransactionEnums;
  public teamEnums = TeamEnums;
  public subscribeObjectList: ISubscription[] = [];

  constructor(public fileSearchService: FileSearchService)
  // private loggerService: LoggerService
  { }

  ngOnInit(): void {
    /* */
  }

  private updatedTransactionDetails = new Subject<any[]>();
  updatedTransactionDetails$ = this.updatedTransactionDetails.asObservable();

  private updatedTransactionTeam = new Subject<any[]>();
  updatedTransactionTeam$ = this.updatedTransactionTeam.asObservable();

  private updatedTransactionHeader = new Subject<TransactionInfoHeaderDto>();
  updatedTransactionHeader$ = this.updatedTransactionHeader.asObservable();

  // get transaction info for transaciton tab content
  public getTransactionsPreview(fileId: number): void {
    if (CommonService.isTruthyValue(fileId)) {
      const transactionPreview = this.fileSearchService.getTransactionInfo(fileId).subscribe(
        (res: TransactionPreviewDto) => {
          if (res) {
            this.transactionPreview = res;
            this.headerData();
            this.transactionDetailsData();
            this.transactionTeamData();
          }
        },
        (_err: HttpErrorResponse) => {
          this.header = '';
          this.fileNumber = this.fileNo;
          this.address = '';

          this.transactionDetails = [];
          this.transactionTeams = [];

        }
      );
      this.subscribeObjectList.push(transactionPreview);
    }
  }

  public headerData(): void {
    const header = this.transactionPreview.header;
    this.header = header.buyerSellerLastNames;
    this.fileNumber = header.fileNumber;
    this.address = header.fullAddress;

    this.updatedTransactionHeader.next({
      header: this.header,
      fileNumber: this.fileNumber,
      address: this.address,
    });
  }

  public transactionDetailsData(): void {
    const details = this.transactionPreview.transactionDetails;
    this.getLoans(details);
    this.formatDate(details);
    this.keepKeys(details);
    this.formatPrice(details);
    this.formatLoanNumber(details);
    const filteredDetails: DisplayDetailDto = this.sortObj(details);
    this.removeKeys(filteredDetails);
    this.transactionDetails = [];
    this.transactionDetails.length = 0;
    // eslint-disable-next-line prefer-const
    for (let [key, value] of Object.entries(filteredDetails)) {
      const transactionItems = {};
      for (const enumKey in this.transactionEnums) {
        if (key === enumKey) {
          key = this.transactionEnums[enumKey];
        }
      }
      transactionItems['itemName'] = key;
      transactionItems['itemValue'] = value;
      this.transactionDetails.push(transactionItems);
    }
    this.updatedTransactionDetails.next(this.transactionDetails);
  }

  public transactionTeamData(): void {
    const team = this.transactionPreview.transactionTeam;
    const filteredTeams: DisplayTeamDto = this.sortTeamLabel(team);
    this.removeKeys(filteredTeams);
    this.transactionTeams = [];
    for (let [key, value] of Object.entries(filteredTeams)) {
      const teamItems = {};

      if (value !== null || value !== '') {
        for (const enumKey in this.teamEnums) {
          if (enumKey === key) {
            key = this.teamEnums[enumKey];
            if (value.employeeId) {
              this.removeKeys(value);
              let fullName = '';
              let businessPhone = '';
              let email = '';

              fullName = this.getOfficerName(value.firstName, value.lastName);

              businessPhone = value.businessPhone;
              // TODO: may use in the future implementation
              // value.businessPhone
              //     ? (businessPhone = `<div class="contact__wrapper"><div><img
              //     class="parties-card__icon"
              //     src="./assets/icons/icon-call-dark.svg"
              //     alt="phone icon"/></div><div class="team__email">${value.businessPhone}</div></div>`)
              //     : (businessPhone = undefined);

              email = value.eMail;
              // TODO: may use in the future implementation
              // value.eMail
              //     ? (email = `<div class="contact__wrapper"><div><img
              //     class="parties-card__icon"
              //     src="./assets/icons/icon-mail-dark.svg"
              //     alt="email icon"/></div><div class="team__email">${value.eMail}</div></div>`)
              //     : (email = undefined);

              const contactInfo = [fullName, businessPhone, email];
              const teamValue = this.formatTeamContactInfo(contactInfo);

              value = teamValue.join('');
            }
          }
        }

        teamItems['itemName'] = key;
        teamItems['itemValue'] = value;
        this.transactionTeams.push(teamItems);
      }
    }
    this.updatedTransactionTeam.next(this.transactionTeams);
  }

  public getOfficerName(firstName: string, lastName: string): string {
    let fullName = '';
    if (!firstName) {
      fullName = lastName;
    } else if (!lastName) {
      fullName = firstName;
    } else {
      fullName = `${firstName} ${lastName}`;
    }

    return fullName;
  }

  public formatTeamContactInfo(contactInfo: any[]): any {
    const teamValue = contactInfo.map((contact) => {
      if (contact !== undefined) {
        return `${contact}\n`;
      }
    });

    return teamValue;
  }

  public sortTeamLabel(obj: TransactionPreviewDto['transactionTeam']): DisplayTeamDto {
    const sortedTeamLabel: DisplayTeamDto = new DisplayTeamDto();
    Object.keys(this.teamEnums).forEach((key) => {
      sortedTeamLabel[key] = obj[key];
    });
    return sortedTeamLabel;
  }

  public sortObj(obj: TransactionPreviewDto['transactionDetails']): DisplayDetailDto {
    const sortedObj: DisplayDetailDto = new DisplayDetailDto();
    Object.keys(this.transactionEnums).forEach((key) => {
      sortedObj[key] = obj[key];
    });
    return sortedObj;
  }

  public keepKeys(obj: TransactionPreviewDto['transactionDetails']): void {
    const keepEnumKeys = ['businessSegmentName', 'transactionType', 'openDate'];
    keepEnumKeys.forEach((key) => {
      if (obj[key] === null || obj[key] === '' || obj[key] === undefined) {
        obj[key] = ' ';
      }
    });
  }

  public getLoans(transactionDetails: TransactionPreviewDto['transactionDetails']): any {
    const loans = transactionDetails.newLoans;
    const loanItem = {};
    const loansKeys = [
      'firstNewLoan',
      'secondNewLoan',
      'thirdNewLoan',
      'fourthNewLoan',
      'fifthNewLoan',
      'sixthNewLoan',
      'seventhNewLoan',
      'eighthNewLoan',
      'ninethNewLoan',
    ];
    for (let i = 0; i < loans.length; i++) {
      if (loans[i].lender || loans[i].loanAmount) {
        loanItem[loansKeys[i]] = loans[i].loanAmount;
      }
    }
    transactionDetails = Object.assign(transactionDetails, loanItem);
    return transactionDetails;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public removeKeys(obj: any): any {
    for (const key in obj) {
      if (obj[key] === null || obj[key] === '' || obj[key] === undefined) {
        delete obj[key];
      }
    }
    return obj;
  }

  public formatLoanNumber(obj: TransactionPreviewDto['transactionDetails']): void {
    for (const property in obj) {
      if (property.toLowerCase().indexOf('loan') > -1 && !isNaN(obj[property])) {
        obj[property] = `$${this.formatCurrency(obj[property])}`;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public formatPrice(obj: any): void {
    const p = 'salesPrice';
    // TODO: lint review
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const key in obj) {
      if (obj[p] && obj[p] > 0) {
        obj[p] = `$${this.formatCurrency(obj[p])}`;
        return;
      } else {
        delete obj[p];
      }
    }
  }

  public formatCurrency(val: string): any {
    return Number(parseFloat(val).toFixed(2)).toLocaleString('en', {
      minimumFractionDigits: 2,
    });
  }

  public formatDate(obj: TransactionPreviewDto['transactionDetails']): void {
    const dateCategories = ['openDate', 'estimatedSettlementDate', 'settlementDate', 'prorateDate'];
    dateCategories.forEach((val) => {
      if (obj[val]) {
        const originalDate = new Date(obj[val]);
        const year = originalDate.getFullYear();
        const month = originalDate.getMonth() + 1;
        const date = originalDate.getDate();
        obj[val] = `${month}/${date}/${year}`;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscribeObjectList.length > 0) {
      this.subscribeObjectList.forEach((s) => {
        if (s) {
          s.unsubscribe();
        }
      });
    }
  }
}
