import { Component, OnInit, ViewChild, ChangeDetectorRef, NgZone, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, finalize } from 'rxjs';
import { ListDataModel } from 'src/app/models/listingDataModal';
import { FileSearchService } from 'src/app/global/services/file-search.service';
import { getUserInfo, hideSidebar } from '@igps/client-root-config';
import { environment } from 'src/environments/environment';
import { UtilityService } from 'src/app/global/services/utility.service';
import { TransactionsInfoComponent } from '../transactions-info/transactions-info.component';
import { Location } from '@angular/common';
import { PagingModel } from 'src/app/models/pagingModel';
import { SearchResultTaskId, onChangeProcess, GetSelectedProcess, EmailMessageId } from '@igps/top-utility';
import { mountRootParcel } from 'single-spa';
@Component({
  selector: 'app-file-search-results',
  templateUrl: './file-search-results.component.html',
  styleUrls: ['./file-search-results.component.scss']
})
export class FileSearchResultsComponent implements OnInit {
  showTitle: boolean;
  title: string = "Select a file to view task(s)";
  pageNumber!: number;
  currentPage: number = 1;
  showPagination: boolean = true;
  dataModel = new ListDataModel();
  searchField: string = this.route.snapshot.params['searchField'];
  searchType: string = this.route.snapshot.params['searchType'];
  newSerachText!: string;
  totalResultCount!: number;
  SearchResultsSubscription$!: Subscription;
  pageInfo: PagingModel = {
    PageRequest: {
      PageNumber: 1,
      PageSize: 40,
    },
    SortRequest: {
      Field: 'Name.keyword',
      Direction: 1,
    },
    Filters: [],
  };
  TasksCount: number = 1
  pageSize!: number;
  isError!: boolean;
  errorMessage!: string;
  titleRowWidth = 0;
  showSpinner: boolean = false;
  public dataSource = new MatTableDataSource<ListDataModel>();
  displayedColumns: string[] = ['fileNumber', 'tasks', 'buyerName', 'sellerName', 'address', 'owningOffice', 'openDate', 'fileStatus', 'transactionType', 'serviceType'];
  totalCount!: number;
  userInfo!: any;
  gridSpinnerSubscription: Subscription;
  hasResults: boolean = true;
  hasSelectedCategoryResults: boolean = true;
  selectedRowIndex: any;
  stateData!: any;
  @ViewChild(TransactionsInfoComponent) transInfoComponent: TransactionsInfoComponent;
  selectedfileInfo: any;
  selectedProductionProcessId: any;
  selectedProductionProcessName: any;
  onChangeProcessIdSubscription: Subscription;
  isEmail: boolean = false;
  state!: any;
  emailProps = {}
  mountRootParcel = mountRootParcel;
  config(): Promise<any> {
    return (window as any).System.import(environment.emailParcel);
  }
  selectedTaskSourceType: any;
  constructor(private searchService: FileSearchService, private route: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef,
    private router: Router, private location: Location, private utilityservice: UtilityService, private cdref: ChangeDetectorRef) {
    this.stateData = location.getState();
    let emailid = this.stateData?.emailId ? this.stateData?.emailId : sessionStorage.getItem('selectedEmailNoteTaskId');
    if (emailid) {
      // hideSidebar();
      this.isEmail = true;
    }
  }

  ngOnInit(): void {
    this.utilityservice.setSpinner(true);
    this.title = "";
    this.showTitle = true;
    this.userInfo = getUserInfo();
    this.state = this.location.getState();
    this.emailProps = { emailId: this.state?.emailId ? this.stateData?.emailId : sessionStorage.getItem('selectedEmailNoteTaskId') }
    EmailMessageId.emit(this.state?.emailId ? this.stateData?.emailId : sessionStorage.getItem('selectedEmailNoteTaskId'))
    this.getSearchResults();
    this.selectedRowIndex = 0;
    this.gridSpinnerSubscription = this.utilityservice.showSpinner.subscribe(d => {
      this.showSpinner = d;
      this.cdref.detectChanges();
    })
    this.onChangeProcessIdSubscription = onChangeProcess.subscribe(d => {
      this.selectedProductionProcessId = d.id;
      this.selectedProductionProcessName = d.name;
      this.selectedTaskSourceType = d.taskSource;

    })
    if (!this.selectedProductionProcessId) {
      if (!this.selectedProductionProcessId) {
        this.searchService.getprocessdetails(sessionStorage.getItem('ActiveProcessId')).subscribe((res: any) => {
          if (res) {
            this.selectedProductionProcessId = res.id;
            this.selectedProductionProcessName = res.name;
            this.selectedTaskSourceType = res.taskSource;

          }
        })

      }
    }
  }
  ngOnDestroy(): void {
    this.gridSpinnerSubscription.unsubscribe();
    this.onChangeProcessIdSubscription.unsubscribe();
  }
  getSearchResults() {
    if (!this.selectedProductionProcessId) {
      if (!this.selectedProductionProcessId) {
        this.searchService.getprocessdetails(sessionStorage.getItem('ActiveProcessId')).subscribe((res: any) => {
          if (res) {
            this.selectedProductionProcessId = res.id;
            this.selectedProductionProcessName = res.name;
            this.selectedTaskSourceType = res.taskSource;
          }
        })
      }
    }

    this.newSerachText = this.searchField;
    if (this.stateData.singleSearchResultDetail) {
      this.pageInfo.Filters.push({
        Field: 'fastFileId',
        Value: this.stateData.singleSearchResultDetail.fastFileId.toString(),
        Operator: 1
      }, {
        Field: 'fileNumber',
        Value: this.stateData.singleSearchResultDetail.fileNumber,
        Operator: 1
      });
      this.newSerachText = this.stateData.singleSearchResultDetail.fileNumber;
    }
    this.SearchResultsSubscription$ = this.searchService
      .getBasicSearchResults(this.searchType, this.searchField, (this.selectedProductionProcessId ? this.selectedProductionProcessId : sessionStorage.getItem('ActiveProcessId')), this.pageInfo)
      .pipe(
        finalize(() => {
          this.utilityservice.setSpinner(false);
        })
      )
      .subscribe({
        next: (data: any) => {
          const result = data;
          this.dataSource.data = result?.items;
          this.totalResultCount = data?.totalItemCount;
          this.pageSize = data?.pageSize;
          this.pageNumber = data?.pageNumber;
          this.totalCount = Math.ceil(this.totalResultCount / 40);
          if (this.dataSource.data.length > 0) {
            this.hasResults = true;
            this.changeDetectorRef.detectChanges();
            this.selectedfileInfo = "";
            this.selectedfileInfo = this.dataSource.data[0];
            if (this.isEmail) {
              SearchResultTaskId.emit(this.dataSource.data[0]['fastFileId'] ? this.dataSource.data[0]['fastFileId'] : sessionStorage.getItem('SearchResultTaskId'));
            }
            else {
              this.transInfoComponent.loadUtility(this.dataSource.data[0]['fastFileId'] ? this.dataSource.data[0]['fastFileId'] : sessionStorage.getItem('SearchResultTaskId'));
            }
          }
        },
        error: () => {
          this.utilityservice.setSpinner(false)
        }
      });
  }


  onPageClick(pageNumber?: any) {
    this.currentPage = this.pageInfo.PageRequest.PageNumber = pageNumber;
    if (this.totalResultCount < 40) {
      this.hasResults = false;
      this.hasSelectedCategoryResults = false;
    }
    this.utilityservice.setSpinner(true);
    this.getSearchResults();
  }

  onBack() {
    let emailId = this.state?.emailId ? this.stateData?.emailId : sessionStorage.getItem('selectedTaskId');
    if (emailId) {
      this.router.navigateByUrl(`email-search`, { state: { emailId: emailId } });
    }
    else {
      this.router.navigateByUrl(`${environment.appPath}`);
    }
  }

  onBackToDashbrd() {
    this.navigate();
  }

  navigate() {
    if (this.userInfo.assumedRoleId == 1) {
      this.router.navigate(['manager/dashboard']);
    }
    else {
      this.router.navigate(['processor/dashboard']);
    }
  }

  onViewTask(event: any) {
    let fastfileId = event.fastFileId;

    let psName = this.userInfo.productionSystem?.name;
    let assigneeId = this.userInfo.id;
    this.searchService.openActionDialog(this.selectedProductionProcessId, this.selectedProductionProcessName, psName, event?.rawFileNumber, fastfileId, this.selectedTaskSourceType, assigneeId);
  }
  getTransactionParties(row: any, index: any) {
    this.selectedfileInfo = "";
    this.selectedfileInfo = row;
    this.selectedRowIndex = index;
    if (this.isEmail) {
      SearchResultTaskId.emit(row.fastFileId);
    }
    else {
      this.transInfoComponent.loadUtility(row.fastFileId);
    }
  }
  addNewTask() {
    if (this.selectedfileInfo) {
      this.utilityservice.setSpinner(true);
      let processId = this.selectedProductionProcessId;
      this.searchService.createNewTask(processId, this.selectedfileInfo.fastFileId).subscribe((res: any) => {
        if (res) {
          this.utilityservice.setSpinner(false);
          this.router.navigate(['/process/task', res.id])
        }
      }, err => {
        this.utilityservice.setSpinner(false);
      })
    }
  }

  LinktoNewTask() {
    if (this.selectedfileInfo) {
      this.utilityservice.setSpinner(true);
      let emailId = this.stateData?.emailId ? this.stateData?.emailId : sessionStorage.getItem('selectedTaskId');
      this.searchService.linkNewTaskEmail(emailId, this.selectedfileInfo.fastFileId).subscribe((res: any) => {
        if (res) {
          this.utilityservice.setSpinner(false);
          sessionStorage.setItem("isReloadRequired", "true");
          EmailMessageId.emit(emailId);
          this.router.navigate(['/process/task', res.taskId, { emailId: emailId }], { state: { emailId: emailId } });
        }
      }, err => {
        this.utilityservice.setSpinner(false);
      })
    }
  }
  removeTags(str) {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();

    return str.replace(/(<([^>]+)>)/ig, '');
  }
}
