import { Component, EventEmitter, HostListener, Inject, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription, finalize } from 'rxjs';
import { UtilityService } from 'src/app/global/services/utility.service';
import { getUserInfo } from '@igps/client-root-config';
import { MacroModel, MacroSubCategory, MacrosResponse } from 'src/app/models/macros';
import { HttpErrorResponse } from '@angular/common/http';
import { refreshActions } from '../../email-search/email-search.component';
import { TaskBasicInfo } from 'src/app/models/emailBasicInfo';
import { ActionService } from 'src/app/global/services/action.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-email-action-popup',
  templateUrl: './email-action-popup.component.html',
  styleUrls: ['./email-action-popup.component.scss']
})
export class EmailActionPopupComponent implements OnInit {
  public addAction!: FormGroup;
  public initalValues: any;
  hasChange!: boolean;
  macroList: MacroModel[] = [];
  macroSubcategoryList: MacroSubCategory[] = [];
  taskid!: string;
  actionid !: number;
  userInfo: any;
  disableAction: boolean = false;

  constructor(public dialogRef: MatDialogRef<EmailActionPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      actionName: string;
      actionId: number;
      processId: string;
      labels: string[];
      size: string;
      align: string;
      config: any;
      taskid: string;
    }, private actionService: ActionService, private formBuilder: FormBuilder,
    private utilityservice: UtilityService, private zone: NgZone, private router: Router) {
  }

  ngOnInit() {
    this.utilityservice.setSpinner(true);
    this.taskid = this.data.taskid;
    this.initializeForm();
    this.actionid = this.data.actionId;
    this.userInfo = getUserInfo();
    const isOnshore: boolean = (this.userInfo?.site?.name as string)?.toLowerCase() === 'onshore' ? true : false;
    const isOffshore: boolean = ((this.userInfo?.site?.name as string)?.toLowerCase() === 'bangalore' || (this.userInfo?.site?.name as string)?.toLowerCase() === 'hyderabad') ? true : false;
    this.actionService.getMacroSubCategory(this.data.actionId).subscribe(data => {
      this.utilityservice.setSpinner(false);
      this.macroSubcategoryList = this.macroSubcategoryList.concat(data);
    }, err => {
      this.utilityservice.setSpinner(false);
    });
    this.actionService.getMacro(this.data.actionId, this.data.processId, isOnshore, isOffshore).subscribe(data => {
      this.utilityservice.setSpinner(false);
      this.macroList = this.macroList.concat(data);
    }, err => {
      this.utilityservice.setSpinner(false);
    });
  }

  onMacrosChange() {
    this.addAction.controls['notes'].setValue('');
    if (this.addAction.controls["macros"].value?.length > 0) {
      for (let index = 0; index < this.addAction.controls["macros"].value.length; index++) {
        const item: MacroModel = this.addAction.controls["macros"].value[index] as MacroModel;
        const description = index === 0 ? item.description : `\n${item.description}`;
        this.addAction.controls['notes'].setValue(this.addAction.controls['notes'].value + description);
      }
      this.addAction.controls['notes'].setValue(this.addAction.controls['notes'].value.replace(/\n$/, ''));
    }
  }

  initializeForm() {
    this.addAction = this.formBuilder.group(
      {
        macros: [],
        notes: ['', [Validators.minLength(1), Validators.maxLength(3000), Validators.required]]
      });
    if (this.data.actionName === 'Send to Processor') {
      this.addAction.controls['notes'].clearValidators();
    }
    this.initalValues = this.addAction.value;
    this.addAction.valueChanges.subscribe(() => {
      this.hasChange = Object.keys(this.initalValues).some(key => this.addAction.value[key] !== this.initalValues[key])
    });
  }

  getCountOfRemainingLetters() {
    const controlName = 'notes';
    return (3000 - this.addAction?.controls[controlName]?.value?.length);
  }

  public submitaction = (_macroForm: any) => {
    this.utilityservice.setSpinner(true);
    this.addAction.controls['notes'].setValue(this.addAction.controls['notes'].value.trim());
    if (this.addAction.controls['notes'].value.length === 0 && !(this.data.actionName === 'Send to Processor')) {
      return;
    }
    const macroids = _macroForm.macros ? (_macroForm.macros as MacroModel[]).map(macro => macro.id) : [];
    const addAction: MacrosResponse = {
      MacroIds: macroids,
      Note: _macroForm.notes
    };
    this.zone.run(() => {

      this.disableAction = true;
      this.actionService.submitAction(addAction, this.taskid, this.actionid).pipe(finalize(() => {
        this.disableAction = false;
        this.utilityservice.setSpinner(false);
        refreshActions.emit();
      }))
        .subscribe({
          next: () => {
            this.utilityservice.showToast.emit({ message: 'Submitted successfully', isError: false });
            sessionStorage.setItem('emailStatusId', this.actionid.toString())
            // ReloadTaskBasicInfoEvent.emit();
          },
          error: (error: HttpErrorResponse) => {
            if (error?.error.Status !== 409) {
              this.utilityservice.showToast.emit({ message: 'Error submitting', isError: true });
            } else {
              this.utilityservice.showToast.emit({ message: 'Error occurred', isError: true });
            }
            this.dialogRef.close('error');
            this.utilityservice.setSpinner(false);
          },
          complete: () => {
            this.utilityservice.setSpinner(false);
            this.dialogRef.close('success');
            sessionStorage.removeItem('emailAssignedTo');
            sessionStorage.removeItem('emailStatusId');
            this.navigate()
          }
        });
    });

  }
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasChange;
  }
  close(message: string): void {
    this.dialogRef.close(message.toLowerCase());
  }

  navigate() {
    if (this.userInfo.assumedRoleId == 1) {
      this.router.navigate(['manager/dashboard']);
    }
    else {
      this.router.navigate(['processor/dashboard']);
    }
  }

}

