import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ErrorModel } from '../models/errorModel';

@Injectable({
  providedIn: 'root'
})

export class LoggerService {

  readonly igpsBaseUrl = `${environment.igpsBaseUrl.apiUrl}`

  constructor(private httpClient: HttpClient) { }

  logApplicationErrors(error: ErrorModel) {
    return this.httpClient.post(`${this.igpsBaseUrl}` + `/logger/log/v1`, error);
  }
}
