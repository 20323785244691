import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { SearchModes } from 'src/app/global/enums/search-mode-enums';
import { PredictiveDisplay } from 'src/app/global/models/file-search/predictive-display';
import { Predictive } from 'src/app/global/models/file-search/predictive-dto';
import { RecentSearchResultDto } from 'src/app/global/models/recent-search-result-dto';
import { FileSearchService } from 'src/app/global/services/file-search.service';
import { getUserInfo } from '@igps/client-root-config';
import { NavigationExtras, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoggerService } from 'src/app/global/services/logger.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-search-results-dropdown',
  templateUrl: './search-results-dropdown.component.html',
  styleUrls: ['./search-results-dropdown.component.scss']
})
export class SearchResultsDropdownComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public filteredRecentSearches: any[];
  @Input() public selectedType: string;
  @Input() public placeholder: any;
  @Input() public predictiveSearchResults: Predictive[];
  @Input() public queryField: UntypedFormControl;
  @Input() public searchMode: string;
  @Input() public showSearchResultsDropdown: boolean;

  public subscribeObjectList: ISubscription[] = [];
  public recentsMapped: any[];
  public predictiveHeading: { [k: string]: string };
  public predictivesMapped: PredictiveDisplay[];
  public predictivesDescription: string;
  public searchingMessage = 'Searching...';
  public recentGridClass = 'grid--50-50-columns';
  public predictiveGridClass: string;
  public recentLimit = 10;
  public selectedFastFileId: number;
  public selectedFastFileNo: string;
  userInfo!: any;
  constructor(private fileSearchService: FileSearchService, private router: Router, private loggerService: LoggerService, private location: Location) { }
  ngOnInit(): void {
    this.userInfo = getUserInfo();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchMode) {
      this.handleUpdates(this.searchMode);
    }
  }
  public handleUpdates(searchMode: string): void {
    switch (searchMode) {
      case 'Inactive':
        this.showSearchResultsDropdown = false;
        break;
      case 'Searching':
        this.showSearchResultsDropdown = true;
        break;
      case 'RecentsFound':
        this.mapRecents(this.filteredRecentSearches);
        break;
      case 'NoRecentResultsFound':
        this.mapRecents(this.filteredRecentSearches);
        break;
      case 'PredictivesFound':
        this.predictiveHeading = this.fileSearchService.constructHeading(this.selectedType, this.searchMode);
        this.predictiveGridClass = this.fileSearchService.determineLayout(this.selectedType, this.searchMode);
        this.mapPredictives(this.predictiveSearchResults);
        break;
      case 'NoCategoryResultsFound':
        this.predictivesDescription = this.showPredictiveSearchType(this.selectedType);
        this.predictiveHeading = this.fileSearchService.constructHeading(this.selectedType, this.searchMode);
        this.predictiveGridClass = this.fileSearchService.determineLayout(this.selectedType, this.searchMode);
        this.mapPredictives(this.predictiveSearchResults);
        break;
    }
  }
  public mapPredictives(predictives: Predictive[]): void {
    this.predictivesMapped = predictives.map((result) => {
      const display = <PredictiveDisplay>result;
      const option = result.category;
      switch (option) {
        case 'Property Address':
          display.left = result.searchSuggestion;
          display.right = result.buyerSeller;
          break;
        default:
          display.left = result.searchSuggestion;
          display.right = result.propertyAddress;
      }

      return display;
    });
  }
  public mapRecents(filteredRecentSearches: any[]): void {
    this.recentsMapped = filteredRecentSearches.slice(0, this.recentLimit).map((result) => {
      result.searchResultsSummary = this.getSearchResultsSummary(result);

      return result;
    });
  }
  public getSearchResultsSummary(search: RecentSearchResultDto): string {
    if (search['searchResultCount'] === 1 && search.singleSearchResultDetail) {
      return search['singleSearchResultDetail']['searchSuggestion'];
    } else if (search['searchResultCount'] >= 1 && !search.singleSearchResultDetail) {
      return 'Result(s)';
    } else {
      return 'No results';
    }
  }
  public showPredictiveSearchType(type: string): string {
    switch (type) {
      case 'PropertyAddress':
        return 'property addresses';

      case 'PartyName':
        return 'party names';

      case 'FileNumber':
        return 'file numbers';

      case 'ApnTaxNumber':
        return 'APN/tax numbers';

      case 'ReferenceNumber':
        return 'reference numbers';

      case 'InvoiceNumber':
        return 'invoice numbers';

      default:
        return '';
    }
  }
  public selectPredictiveResult(predictiveResult: PredictiveDisplay): void {
    this.selectedFastFileId = predictiveResult.fastFileId;
    this.selectedFastFileNo = predictiveResult.fastFileNumber;
    this.fileSearchService.pageNumber = 1;
    this.closeDropdown();
    this.saveSearch(
      this.selectedType,
      predictiveResult.left,
      predictiveResult.fastFileId,
      predictiveResult.fastFileNumber,
      predictiveResult.right,
      true // response
    );
  }
  public saveSearch(
    type: string,
    text: string,
    fastFileId: number,
    fileNumber: number | string,
    searchSuggestion: string,
    fileAccess: boolean
  ): void {
    const searchText = text ? this.fileSearchService.removeEm(text) : 'Error';
    const searchType = type ? type.toString().toLowerCase() : 'all';
    const recentSearch = <RecentSearchResultDto>{
      userId: this.userInfo?.basicInfo?.email,
      searchType: searchType,
      searchText: searchText,
      searchResultCount: 1,
      singleSearchResultDetail: {
        fileNumber: fileNumber,
        fastFileId: fastFileId,
        searchSuggestion: searchSuggestion,
        fileAccess: fileAccess,
      },
    };

    try {
      const subscribeObject = this.fileSearchService.saveRecentSearchResults(recentSearch).subscribe({
        next: () => {
          this.selectRecentResult(recentSearch);
          // const navigationExtras: NavigationExtras = {
          //   state: {
          //     singleSearchResultDetail: recentSearch.singleSearchResultDetail,
          //     totalCount: recentSearch.searchResultCount,
          //     searchText: recentSearch.searchText
          //   }
          // };
          // console.log(this.location.getState());
          // this.router.navigateByUrl(`${environment.appPath}/${this.selectedType}/${recentSearch.searchText}`, navigationExtras);
        },
        error: (errorInfo: any) => {
          this.loggerService.logApplicationErrors(errorInfo).subscribe();
        }
      });
      this.subscribeObjectList.push(subscribeObject);
    } catch (e) {
      console.error(e);
    }
  }
  public selectRecentResult(recentSearch: RecentSearchResultDto): void {
    const navigationExtras: NavigationExtras = {
      state: {
        singleSearchResultDetail: recentSearch.singleSearchResultDetail,
        totalCount: recentSearch.searchResultCount,
        searchText: recentSearch.searchText
      }
    };
    let emailState: any = this.location.getState();
    if (emailState?.emailId) {
      const emailNavigationExtras: NavigationExtras = {
        state: {
          singleSearchResultDetail: recentSearch.singleSearchResultDetail,
          totalCount: recentSearch.searchResultCount,
          searchText: recentSearch.searchText,
          emailId: emailState?.emailId
        }
      };
      this.router.navigateByUrl(`email-search/${this.selectedType}/${recentSearch.searchText}`, emailNavigationExtras);
    }
    else {
      this.router.navigateByUrl(`${environment.appPath}/${this.selectedType}/${recentSearch.searchText}`, navigationExtras);
    }
  }
  public closeDropdown(): void {
    this.showSearchResultsDropdown = false;
    this.searchMode = SearchModes.Inactive;
  }
  ngOnDestroy(): void {
    if (this.subscribeObjectList.length > 0) {
      this.subscribeObjectList.forEach((s) => {
        if (s) {
          s.unsubscribe();
        }
      });
    }
  }
}
