<ng-container *ngIf="showSearchResultsDropdown">
    <div class="search-container__search-dropdown__container">
        <mat-tab-group *ngIf="searchMode == 'Searching'"
            class="ig2 search-container__search-dropdown predictive__search-dropdown">
            <mat-tab>
                <div class="general__search-no-predictive-results">
                    <div class="message-searching">
                        <div class="message">
                            {{ searchingMessage }}
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
        <mat-tab-group *ngIf="searchMode == 'NoResultsFound'"
            class="ig2 search-container__search-dropdown predictive__search-dropdown">
            <mat-tab>
                <div class="general__search-no-predictive-results">
                    <div class="message-container">
                        <div class="message">
                            No exact matches found. Press 'Enter' to see all
                            search results.
                        </div>
                        <div class="title">Suggestions:</div>
                        <div class="list-container">
                            <ul class="list">
                                <li class="padded">Check your spelling</li>
                                <li class="padded">Try different keywords</li>
                                <li class="padded">
                                    Try more general keywords
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
        <mat-tab-group *ngIf="
                searchMode == 'PredictivesFound' ||
                searchMode == 'NoCategoryResultsFound'
            " class="ig2 search-container__search-dropdown predictive__search-dropdown">
            <mat-tab>
                <p *ngIf="searchMode == 'NoCategoryResultsFound'" class="predictive__no-match">
                    No matching {{ predictivesDescription }} found. Here are
                    some suggestions from other categories.
                </p>
                <div class="">
                    <div class="general__search-content general__search-headers header grid"
                        [ngClass]="predictiveGridClass">
                        <div class="general__search-header-title cell">
                            {{ predictiveHeading.left }}
                        </div>
                        <div class="general__search-header-title cell">
                            {{ predictiveHeading.right }}
                        </div>
                    </div>

                    <div (click)="selectPredictiveResult(predictiveSearchResult)"
                        *ngFor="let predictiveSearchResult of predictivesMapped" class="general__search-content grid"
                        [ngClass]="predictiveGridClass">
                        <div class="cell">
                            <div class="truncate" innerHTML="{{ predictiveSearchResult['left'] }}"></div>
                        </div>
                        <div class="cell">
                            <div #searchSuggestion class="truncate" innerHTML="{{
                                    predictiveSearchResult['right']
                                }}"></div>
                        </div>
                    </div>
                </div>
                <!-- <app-advanced-search></app-advanced-search> -->
            </mat-tab>
        </mat-tab-group>
        <mat-tab-group *ngIf="
                searchMode == 'RecentsFound' ||
                searchMode == 'NoRecentResultsFound'
            " class="ig2 search-container__search-dropdown predictive__search-dropdown">
            <mat-tab label="Recent">
                <div class="row general__search-content general__search-headers header grid"
                    [ngClass]="recentGridClass">
                    <div class="general__search-header-title cell">
                        Search Query
                    </div>
                    <div class="general__search-header-title cell">Results</div>
                </div>
                <div class="no__records-alert general__search-content" *ngIf="searchMode == 'NoRecentResultsFound'">
                    No recent searches found!
                </div>
                <div *ngFor="let recentSearch of recentsMapped" class="row general__search-content grid"  (click)="selectRecentResult(recentSearch)"
                    [ngClass]="recentGridClass">
                    <div class="cell">
                        <div class="truncate" innerHTML="{{ recentSearch.searchText }}"></div>
                    </div>
                    <div class="cell">
                        <div class="truncate" innerHTML="{{ recentSearch.searchResultsSummary }}"></div>
                    </div>
                </div>
                <!-- <app-advanced-search></app-advanced-search> -->
            </mat-tab>
            <mat-tab label="Saved" class="saved__search-tab">
                <!--<div
                    class="row general__search-content"
                    *ngFor="
                        let recentSearch of filteredRecentSearches | slice: 0:10
                    "
                >
                    <div class="column">
                        <p class="table-row">
                            {{ recentSearch['searchText'] | truncates: 32 }}
                        </p>
                    </div>
                </div>-->
                <!-- <app-advanced-search></app-advanced-search> -->
            </mat-tab>
        </mat-tab-group>
    </div>
</ng-container>