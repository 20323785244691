import { Injectable } from '@angular/core';
import { SearchTypes } from '../enums/search-types.enum';
import { SearchModes } from '../enums/search-mode-enums';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { RecentSearchResultDto } from '../models/recent-search-result-dto';
import { SearchDescription } from '../models/search-description';
import { environment } from '../../../environments/environment';
import { PartiesDto } from '../models/transaction-parties-dto/parties-dto';
import { ViewTaskPopupComponent } from 'src/app/components/view-task-popup/view-task-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { PagingModel } from 'src/app/models/pagingModel';

@Injectable({
  providedIn: 'root'
})
export class FileSearchService {

  readonly igpsBaseUrl = `${environment.igpsBase.apiUrl}`;
  public pageNumber = 1;
  public defaultPageSize = 40;
  public pageSize = this.defaultPageSize;
  private searchObjectState: BehaviorSubject<SearchDescription> = new BehaviorSubject<SearchDescription>(<SearchDescription>{});
  private searchRequestedState: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);
  constructor(public http: HttpClient, private matDialog: MatDialog) {
  }
  public searchTypes = SearchTypes;

  public constructHeading(category: string, searchMode: string): { left: string; right: string } {
    const result = { left: '', right: '' };
    const option = this.modifyCategory(category, searchMode);

    switch (option) {
      case 'PropertyAddress':
        result.left = 'Property Address';
        result.right = 'Buyer/Seller';
        break;
      case 'All':
        result.left = 'Search Suggestions';
        result.right = 'Additional Info';
        break;
      default:
        result.left = this.searchTypes[category];
        result.right = 'Property Address';
    }

    return result;
  }
  public resetSearchPagination(): void {
    this.pageNumber = 1;
    this.pageSize = this.defaultPageSize;
  }
  get searchObject(): SearchDescription {
    return this.searchObjectState.getValue();
  }

  set searchObject(newValue: SearchDescription) {
    this.searchObjectState.next(newValue);
  }
  get searchRequestedTime(): Date {
    ///
    return this.searchRequestedState.getValue();
  }

  set searchRequestedTime(newValue: Date) {
    this.searchRequestedState.next(newValue);
  }
  public determineLayout(category: string, searchMode: string): string {
    let result = 'grid--40-60-columns';
    const layoutCategory = this.modifyCategory(category, searchMode);
    if (layoutCategory === 'PropertyAddress' || layoutCategory === 'All') {
      result = 'grid--50-50-columns';
    }

    return result;
  }

  public modifyCategory(category: string, searchMode: string): string {
    let option = category;
    if (searchMode === SearchModes.NoCategoryResults) {
      option = 'All';
    }

    return option;
  }
  public removeEm(inputString: string): string {
    const result = inputString.replace(/<em>/g, '').replace(/<\/em>/g, '');

    return result;
  }
  public getPredictiveSearchResults(category: string, searchText: string) {
    return this.http.get(`${this.igpsBaseUrl}/FileSearch/predictivesearch?searchType=${category}&searchText=${searchText}`)
  }

  getRecentSearchResults(selectedType: string, userId: any): any {
    return this.http.get(`${this.igpsBaseUrl}/FileSearch/recentsearch?searchType=${selectedType}&userId=${userId}`);
  }
  public saveRecentSearchResults(recentSearch: RecentSearchResultDto): Observable<string> {
    return this.http.post(`${this.igpsBaseUrl}/FileSearch/recentsearch`, recentSearch, { responseType: 'text' });
  }
  getBasicSearchResults(searchType: string, searchField: string, processId: string, pageInfo: PagingModel): Observable<any> {
    return this.http.post(`${environment.igpsBaseUrl.apiUrl}/gentask/gentask/v1/task/taskCount/${searchType}/${searchField}/${processId}`, pageInfo);
  }
  getTransactionInfo(fileId: number): Observable<any> {
    return this.http.get(`${this.igpsBaseUrl}/FastFile/transactionpreview?fileId=${fileId}`);
  }
  public fetchPartiesDetails(fileId: string | number): Observable<PartiesDto> {

    return this.http.get<PartiesDto>(`${this.igpsBaseUrl}/FastFile/transactionparties?fileId=${fileId}`)
  }

  getAllTaskList(processId: string, fastfileId) {
    return this.http.get(`${environment.igpsBaseUrl.apiUrl}/gentask/gentask/v1/task/${processId}/list/${fastfileId}`)
  }
  openActionDialog(processId, processname, psName, searchtext, fastfileId, sourceType, assigneeId) {
    return this.matDialog.open(ViewTaskPopupComponent, {
      panelClass: ['mat-dialog-container-large', 'ig2'],
      data: {
        processId,
        processname,
        psName,
        searchtext,
        fastfileId,
        sourceType,
        assigneeId
      },
      disableClose: true,
    });
  }
  getNotesdetails(id: any) {
    return this.http.get(`${environment.igpsBaseUrl.apiUrl}/gentask/fileutility/v1/notes/all/${id}`)
  }
  createNewTask(processId: string, fastfileId: any) {
    let body = {
      "ProcessId": processId,

      "FastFileId": fastfileId
    }
    return this.http.post(`${environment.igpsBaseUrl.apiUrl}/gentask/gentask/v1/task/createnewtask`, body)
  }

  linkNewTaskEmail(emailMsgId: any, fastFileId: any) {
    let req = {
      "emailMessageId": emailMsgId,
      "fastFileId": fastFileId,
      "createNewTask": true
    }
    return this.http.post(`${environment.igpsBaseUrl.apiUrl}/emailservice/dashboard/v1/email/link`, req)
  }

  LinkEmailtoExisting(emailMessageId: any, taskId: any) {
    let req = {
      "emailMessageId": emailMessageId,
      "taskId": taskId,
      "createNewTask": false
    }
    return this.http.post(`${environment.igpsBaseUrl.apiUrl}/emailservice/dashboard/v1/email/link`, req)
  }
  Assignee(taskId: string, assigneeId: string) {
    return this.http.post(`${environment.igpsBaseUrl.gentaskurl}/gentask/v1/task/${taskId}/staff/${assigneeId}`, null);
  }
  getprocessdetails(id) {
    return this.http.get(`${environment.igpsBaseUrl.apiUrl}/domaindata/prdprocess/v1/id/${id}`);
  }
}
