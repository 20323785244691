<div mat-dialog-title class="mat-dialog-title">
    <div>Move the Email Message</div>
    <img mat-dialog-close class="dialog__header-icon" src="assets/icons/icon-close-dark.svg" alt="close icon"
        (click)='close("success")' />
</div>
<mat-dialog-content mat-dialog-content class="mat-dialog-content mat-typography mat-dialog-content">
    <form id="submitAction" [formGroup]="moveEmail" autocomplete="off" novalidate>
        <div class="text-container">
            <span>Select the process below and press submit</span>
        </div>
        <div class="process-row">
            <reactive-filter-select [options]="productionProcessList" label="Select Process"
                formControlName="processId"></reactive-filter-select>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="mat-dialog-actions" align="end">
    <button mat-flat-button mat-dialog-close type="button"
        class="ig2 button button--secondary button--small mat-button mat-button-base btn-padding"
        (click)='close("success")'>Cancel</button>
    <button type="submit" mat-flat-button
        class="ig2 button button--primary button--small mat-button mat-button-base" cdkFocusInitial
        [disabled]="!moveEmail.valid" (click)="moveEmailSubmit(moveEmail.value)">Submit</button>
</mat-dialog-actions>