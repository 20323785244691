import { ServiceContactDto } from './service-contact-dto';

export class DisplayTeamDto {
  escrowOwningOfficeName?: string;
  escrowOfficer?: ServiceContactDto;
  escrowAssistant?: ServiceContactDto;
  titleOwningOfficeName?: string;
  titleOfficer?: ServiceContactDto;
  titleAssistant?: ServiceContactDto;
}
