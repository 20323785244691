import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActionService } from 'src/app/global/services/action.service';
import { getUserInfo } from '@igps/client-root-config';
import { GetSelectedProcess } from '@igps/top-utility';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserInfo } from 'src/app/models/userInfo.interface';
import { ProductionProcess } from 'src/app/models/macros';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/global/services/utility.service';
import { finalize } from 'rxjs';
import { refreshActions } from '../email-search/email-search.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-move-email-popup',
  templateUrl: './move-email-popup.component.html',
  styleUrls: ['./move-email-popup.component.scss']
})
export class MoveEmailPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<MoveEmailPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      emailMessageId: string
    },
    private formBuilder: FormBuilder,
    private zone: NgZone,
    private router: Router,
    private utilityService: UtilityService,
    private actionService: ActionService) {
  }

  userInfo: UserInfo;
  moveEmail: FormGroup;
  productionProcessList: ProductionProcess[] = [];

  ngOnInit(): void {
    this.userInfo = getUserInfo();
    this.moveEmail = this.formBuilder.group({
      processId: ['', [Validators.required]]
    });
    this.actionService.getProductionProcess(this.userInfo.productionSystem.id).subscribe((data) => {
      const emailMessageProcess : { id: string; name: string } | null = GetSelectedProcess();
      data = data.filter(d => d.id !== (emailMessageProcess?.id ? emailMessageProcess.id : sessionStorage.getItem("ActiveProcessId")!));
      this.productionProcessList = this.productionProcessList.concat(data);
    });
  }

  moveEmailSubmit(moveEmailForm: any){
    this.zone.run(() =>{
      this.utilityService.setSpinner(true);
      this.actionService.moveEmailsubmitAction(this.data.emailMessageId, moveEmailForm.processId).pipe(finalize(() => {
        this.utilityService.setSpinner(false);
        refreshActions.emit();
      }))
      .subscribe({
        next: (response) => {
          this.utilityService.showToast.emit({ message: `Message Moved to ${response.processName}`, isError: false });
        },
        error: (error: HttpErrorResponse) => {
          if (error?.error.Status !== 409) {
            this.utilityService.showToast.emit({ message: 'Error submitting', isError: true });
          } else {
            this.utilityService.showToast.emit({ message: 'Error occurred', isError: true });
          }
          this.dialogRef.close('error');
        },
        complete: () => {
          this.dialogRef.close('success');
          this.navigate();
        }
      });
    });
  }

  close(message: string): void {
    this.dialogRef.close(message.toLowerCase());
  }

  navigate() {
    if (this.userInfo.assumedRoleId == 1) {
      this.router.navigate(['manager/dashboard']);
    }
    else {
      this.router.navigate(['processor/dashboard']);
    }
  }

}
