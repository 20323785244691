import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { BuyerDto } from 'src/app/global/models/transaction-parties-dto/buyer-dto';
import { ContactDto } from 'src/app/global/models/transaction-parties-dto/contact-dto';
import { PartiesDetailDto } from 'src/app/global/models/transaction-parties-dto/parties-detail-dto';
import { PartiesDto } from 'src/app/global/models/transaction-parties-dto/parties-dto';
import { TransactionInfoParty } from 'src/app/global/models/transaction-info-party';
import { ContactType } from 'src/app/global/enums/contact-type.enum';
import { PartiesAdditionalInfoDto } from 'src/app/global/models/additional-Info-dto';
import { FileSearchService } from 'src/app/global/services/file-search.service';
import { CommonService } from 'src/app/global/services/common.service';
import { TransactionInfoTabService } from 'src/app/global/services/transaction-info-tab.service';

@Component({
  selector: 'app-transactions-info',
  templateUrl: './transactions-info.component.html',
  styleUrls: ['./transactions-info.component.scss']
})
export class TransactionsInfoComponent implements OnDestroy {
  public searchText: string;
  public fileNo: string;
  public fileId: number;
  public header = '';
  public fileNumber: string;
  public address = '';
  //public transactionPreview: TransactionPreviewDto;
  public transactionPartiesDetail: PartiesDto;
  public transactionDetails = [];
  public transactionTeams = [];
  public partyDetails = [];
  public subscribeObjectList: ISubscription[] = [];
  showSpinner: boolean = false;
  public partiesHolder: TransactionInfoParty[] = [];

  constructor(
    public transactionInfoTabService: TransactionInfoTabService,
    public fileSearchService: FileSearchService,
    public router: Router
  ) { }

  loadUtility(fileId: number) {
    this.showSpinner = true;
    this.fileId = fileId;
    this.getUtilityFileInfo();
    this.getTransactionTabInfoFromService();
  }

  public getTransactionTabInfoFromService(): void {
    const transactionDetailSub = this.transactionInfoTabService.updatedTransactionDetails$.subscribe((data) => {
      this.transactionDetails = data;
    });

    const transactionTeamsSub = this.transactionInfoTabService.updatedTransactionTeam$.subscribe((data) => {
      this.transactionTeams = data;
    });

    const transactionHeaderSub = this.transactionInfoTabService.updatedTransactionHeader$.subscribe((data) => {
      this.header = data.header;
      this.fileNumber = data.fileNumber;
      this.address = data.address;
    });
    this.subscribeObjectList.push(transactionDetailSub, transactionTeamsSub, transactionHeaderSub);
  }

  public getUtilityFileInfo(): void {
    this.transactionInfoTabService.getTransactionsPreview(this.fileId);
    this.getPartyDetails(this.fileId);
  }

  // parties content
  public getPartyDetails(fileId: number): void {
    if (CommonService.isTruthyValue(fileId)) {
      const partyDetails = this.fileSearchService.fetchPartiesDetails(fileId).subscribe(
        {
          next: (res: PartiesDto) => {
            if (res) {
              this.transactionPartiesDetail = res;
              this.partiesDetailData();
              this.showSpinner = false;
            }
          },
          error: () => { this.partyDetails = []; }
        });
      this.subscribeObjectList.push(partyDetails);
    }
  }

  public partiesDetailData(): void {
    const otherParties = this.transactionPartiesDetail.otherParties;
    const buyersDetail = this.transactionPartiesDetail.buyers;
    const sellersDetail = this.transactionPartiesDetail.sellers;
    this.partyDetails = [];
    this.getbuyersSellersPartyDetail(buyersDetail, 'Buyer');
    this.getbuyersSellersPartyDetail(sellersDetail, 'Seller');
    const filteredOtherParties: PartiesDetailDto[] = this.getOtherPartiesWithNoNewLenders(otherParties);

    this.getOtherPartiesDetail(filteredOtherParties);
    const newLendersRelatedParties: PartiesDetailDto[] = this.filterOutNewLenders(otherParties);
    this.formatNewLenderRelatedParties(newLendersRelatedParties);
  }

  public getbuyersSellersPartyDetail(details: BuyerDto[], partyRole: string): void {
    details.forEach((value) => {
      const partiesItems = {};
      let businessOrganization = value.businessOrganizationInfo;
      let contacts = value.contacts;
      if (businessOrganization) {
        if (contacts.length > 0) {
          let partyContact = [];
          let entity = '';
          const itemObject = { contacts: [] };
          partyContact = contacts.map((contact) => {
            contact['businessOrganizationName']
              ? (entity = `<div class="business_entity">${contact['businessOrganizationName']}</div>`)
              : (entity = '');
            const phoneType = this.getPhoneType(contact);
            itemObject.contacts = contacts;
            let isPrimary: boolean | null;
            if (contact.principalType === ContactType.husbandWife) {
              const indexOfContact = contacts.indexOf(contact);
              if (indexOfContact === 0) {
                isPrimary = true;
              } else {
                isPrimary = false;
              }
            }

            return this.formatPartiesDetailData(contact, 'contactName', phoneType, 'buyerSeller', isPrimary);
          });
          partiesItems['itemName'] = partyRole;
          partiesItems['itemValue'] = [entity, ...partyContact.join('\n')].join('');
          itemObject['templateType'] = this.mapPrincipalTypeToTemplate(itemObject);
          itemObject['markup'] = this.partiesHolder;
          partiesItems['itemObject'] = itemObject;
          if (businessOrganization) {
            const partyInformation = {};
            const phoneTypeInfo = this.getPhoneType(businessOrganization);
            const partyDetailsInfo = this.formatPartiesDetailData(businessOrganization, 'businessOrganizationName', phoneTypeInfo, undefined, false);
            partyInformation['itemName'] = partyRole;
            partyInformation['itemValue'] = partyDetailsInfo;
            const itemObjectInformation = { contacts: [businessOrganization] };
            itemObjectInformation['markup'] = this.partiesHolder;
            itemObjectInformation['templateType'] = this.mapPrincipalTypeToTemplate(itemObjectInformation);
            partyInformation['itemObject'] = itemObjectInformation;
            partiesItems['dtoType'] = 'businessContacts';
            partiesItems['partyInformation'] = partyInformation;
          }
          this.partyDetails.push(partiesItems);
          this.partiesHolder = [];
          businessOrganization = null;
          contacts = null;
        } else {
          const phoneType = this.getPhoneType(businessOrganization);
          const partyDetails = this.formatPartiesDetailData(businessOrganization, 'businessOrganizationName', phoneType, undefined, false);

          partiesItems['itemName'] = partyRole;
          partiesItems['itemValue'] = partyDetails;
          const itemObject = { contacts: [businessOrganization] };
          itemObject['markup'] = this.partiesHolder;
          itemObject['templateType'] = this.mapPrincipalTypeToTemplate(itemObject);
          partiesItems['itemObject'] = itemObject;
          partiesItems['dtoType'] = 'businessInfo';
          this.partyDetails.push(partiesItems);
          this.partiesHolder = [];
          businessOrganization = null;
          contacts = null;
        }
      } else {
        let partyContact = [];
        let entity = '';
        const itemObject = { contacts: [] };
        partyContact = contacts.map((contact) => {
          contact['businessOrganizationName']
            ? (entity = `<div class="business_entity">${contact['businessOrganizationName']}</div>`)
            : (entity = '');
          const phoneType = this.getPhoneType(contact);
          itemObject.contacts = contacts;
          let isPrimary: boolean | null;
          if (contact.principalType === ContactType.husbandWife) {
            const indexOfContact = contacts.indexOf(contact);
            if (indexOfContact === 0) {
              isPrimary = true;
            } else {
              isPrimary = false;
            }
          }

          return this.formatPartiesDetailData(contact, 'contactName', phoneType, 'buyerSeller', isPrimary);
        });
        partiesItems['itemName'] = partyRole;
        partiesItems['itemValue'] = [entity, ...partyContact.join('\n')].join('');
        itemObject['templateType'] = this.mapPrincipalTypeToTemplate(itemObject);
        itemObject['markup'] = this.partiesHolder;
        partiesItems['itemObject'] = itemObject;
        partiesItems['dtoType'] = 'businessInfo';
        this.partyDetails.push(partiesItems);
        this.partiesHolder = [];
        businessOrganization = null;
        contacts = null;
      }
    });
  }

  public getPhoneType(contact: ContactDto): string {
    let phoneType: string;
    contact['principalType'] === 'Business Entity' ? (phoneType = 'businessPhones') : (phoneType = 'homePhones');
    return phoneType;
  }

  public formatPartiesDetailData(contact: ContactDto, entityName: string, phoneType: string, partyRole: string, isPrimary: boolean | null): string {
    let name = '';
    let contactName = '';
    let phone = '';
    let email = '';

    if (partyRole !== 'buyerSeller') {
      const entity = contact[entityName] ? contact[entityName] : '';
      name = `<div class="contact__wrapper">${entity}</div>`;
    }

    if (contact['contactName']) {
      contactName = `<div class="contact__wrapper">${contact['contactName']}</div>`;
    }
    if (contact['cellulars']?.length > 0) {
      phone = `<div class="contact__wrapper"><img
                                class="parties-card__icon"
                                src="./assets/icons/icon-mobilephone-dark.svg"
                                alt="mobile phone icon"/><div class="team__email">${contact['cellulars'][0]}</div></div>`;
    } else if (contact['phoneType']?.length > 0) {
      phone = `<div class="contact__wrapper"><img
                                class="parties-card__icon"
                                src="./assets/icons/icon-call-dark.svg"
                                alt="phone icon"/><div class="team__email">${contact[phoneType][0]}</div></div>`;
    } else if (contact['businessPhone']?.length > 0) {
      phone = `<div class="contact__wrapper"><img
  class="parties-card__icon"
  src="./assets/icons/icon-call-dark.svg"
  alt="phone icon"/><div class="team__email">${contact['businessPhone']}</div></div>`;
    }
    else {
      phone = '';
    }
    if (contact['email']) {
      email = `<div class="contact__wrapper"><img
                                  class="parties-card__icon"
                                  src="./assets/icons/icon-mail-dark.svg"
                                  alt="email icon"/><div class="team__email">${contact['email']}</div></div>`;
    }
    const result = this.processPartyElement(name, contactName, phone, email, contact, isPrimary);

    return result;
  }

  public getOtherPartiesDetail(details: PartiesDetailDto[]): void {
    const otherPartyData = details.map((x) => ({ contactInformation: x, contactPerson: x.contactPersonDetails }));
    otherPartyData.forEach((value) => {
      const partiesItems = {};
      const partyInformation = {};
      let key = value.contactInformation.role;
      let otherPartiesDetails = '';
      let otherPartiesDetailsInformation = '';
      if (CommonService.isTruthyValue(value.contactPerson)) {
        value.contactPerson.id = value.contactInformation.id;
        value.contactPerson.role = value.contactInformation.role;
        value.contactPerson.businessPartyName = value.contactInformation.businessPartyName;
        value.contactPerson.contactName = value.contactInformation.contactName;
        otherPartiesDetails = this.formatPartiesDetailData(value.contactPerson, 'businessPartyName', 'businessPhones', undefined, false);
        if (key === `Buyer's Real Estate Agent`) { key = `Buyer's RE Agent`; }

        if (key === `Seller's Real Estate Agent`) { key = `Seller's RE Agent` }

        if (key === `Buyer's RE Broker Transaction Coordinator`) { key = `Buyer's TC` }

        if (key === `Seller's RE Broker Transaction Coordinator`) { key = `Seller's TC` }

        if (key === `Lender- Loan Officer`) { key = 'Loan Officer' }

        if (key === `Lender- Processor`) { key = 'Loan Processor' }

        partiesItems['itemName'] = key;
        partiesItems['itemValue'] = otherPartiesDetails;
        const template = this.mapRoleTypeToTemplate(value.contactInformation['roleTypeId'], value.contactPerson.contactName);
        partiesItems['itemObject'] = { contacts: [value.contactPerson], templateType: template, markup: this.partiesHolder };
        if (value.contactInformation) {
          otherPartiesDetailsInformation = this.formatPartiesDetailData(
            value.contactInformation,
            'businessPartyName',
            'businessPhones',
            undefined,
            false
          );
          if (key === `Buyer's Real Estate Agent`) {
            key = `Buyer's RE Agent`;
          }

          if (key === `Seller's Real Estate Agent`) {
            key = `Seller's RE Agent`;
          }

          if (key === `Buyer's RE Broker Transaction Coordinator`) { key = `Buyer's TC`; }


          if (key === `Seller's RE Broker Transaction Coordinator`) { key = `Seller's TC` }

          if (key === `Lender- Loan Officer`) { key = 'Loan Officer'; }

          if (key === `Lender- Processor`) { key = 'Loan Processor' }

          partyInformation['itemName'] = key;
          partyInformation['itemValue'] = otherPartiesDetailsInformation;
          const template = this.mapRoleTypeToTemplate(value.contactInformation['roleTypeId'], value.contactInformation.contactName);
          partyInformation['itemObject'] = { contacts: [value.contactInformation], templateType: template, markup: this.partiesHolder };
          partiesItems['dtoType'] = 'businessContacts';
          partiesItems['partyType'] = 'otherpartiesExceptNewLenders';
          partiesItems['partyInformation'] = partyInformation;
        }
      } else {
        otherPartiesDetails = this.formatPartiesDetailData(value.contactInformation, 'businessPartyName', 'businessPhones', undefined, false);
        if (key === `Buyer's Real Estate Agent`) { key = `Buyer's RE Agent` }

        if (key === `Seller's Real Estate Agent`) { key = `Seller's RE Agent` }

        if (key === `Buyer's RE Broker Transaction Coordinator`) { key = `Buyer's TC` }

        if (key === `Seller's RE Broker Transaction Coordinator`) { key = `Seller's TC` }

        if (key === `Lender- Loan Officer`) { key = 'Loan Officer' }

        if (key === `Lender- Processor`) { key = 'Loan Processor' }

        partiesItems['itemName'] = key;
        partiesItems['itemValue'] = otherPartiesDetails;
        const template = this.mapRoleTypeToTemplate(value.contactInformation['roleTypeId'], value.contactInformation.contactName);
        partiesItems['itemObject'] = { contacts: [value.contactInformation], templateType: template, markup: this.partiesHolder };
        partiesItems['dtoType'] = 'bussinessInfo';
        partiesItems['partyType'] = 'otherpartiesExceptNewLenders';
      }
      this.partyDetails.push(partiesItems);
      this.partiesHolder = [];
      this.sortParties(this.partyDetails);
    });
  }

  public sortParties(partiesDetail: any[]): void {
    partiesDetail.sort((a, b) => {
      const partyA = a.itemName.toUpperCase();
      const partyB = b.itemName.toUpperCase();
      return partyA < partyB ? -1 : partyA > partyB ? 1 : 0;
    });
  }

  public findLastIndexofArray(myArray: any[]): any {
    let findIndex;
    return myArray
      .map(function (e) {
        if (e.itemName.charAt(0) < 'N') {
          findIndex = e.itemName;
          return findIndex;
        }
      })
      .lastIndexOf(findIndex);
  }

  public filterOutNewLenders(details: PartiesDetailDto[]): PartiesDetailDto[] {
    return details.filter((value) => value.role === 'New Lender');
  }

  public getOtherPartiesWithNoNewLenders(details: PartiesDetailDto[]): PartiesDetailDto[] {
    return details.filter((value) => value.role !== 'New Lender');
  }

  public compare_sequence(a: PartiesDetailDto, b: PartiesDetailDto): 1 | -1 {
    if (a.sequenceNumber < b.sequenceNumber) {
      return -1;
    } else {
      return 1;
    }
  }

  public formatNewLenderRelatedParties(parties: PartiesDetailDto[]): void {
    const newLendersData = [];
    const orderedParties = parties.sort(this.compare_sequence);
    orderedParties.forEach((party) => {
      let items = [];
      const contactPerson = party?.contactPersonDetails;
      const contactInformation = party;
      const newLenderRelatedParties = party?.relatedParties;
      if (CommonService.isTruthyValue(contactPerson)) {
        //Statements
        contactPerson.id = contactInformation.id;
        contactPerson.role = contactInformation.role;
        contactPerson.businessPartyName = contactInformation.businessPartyName;
        contactPerson.contactName = contactInformation.contactName;
        const lendersItems = {};
        const itemObject = { contacts: [] };
        let details = '';
        const roleTypeId = party['roleTypeId'];
        details = this.formatPartiesDetailData(contactPerson, 'businessPartyName', 'businessPhone', undefined, false);
        lendersItems['itemName'] = contactPerson.role;
        lendersItems['itemValue'] = details;
        itemObject['templateType'] = this.mapRoleTypeToTemplate(roleTypeId, contactPerson?.contactName);
        itemObject['markup'] = this.partiesHolder;
        itemObject['contacts'] = [contactPerson];
        lendersItems['itemObject'] = itemObject;
        this.partiesHolder = [];
        if (CommonService.isTruthyValue(contactInformation)) {
          const partyInformation = {};
          const itemObject = { contacts: [] };
          let details = '';
          const roleTypeIdDetail = party['roleTypeId'];
          details = this.formatPartiesDetailData(contactInformation, 'businessPartyName', 'businessPhone', undefined, false);
          partyInformation['itemName'] = contactInformation.role;
          partyInformation['itemValue'] = details;
          partyInformation['templateType'] = this.mapRoleTypeToTemplate(roleTypeIdDetail, contactInformation?.contactName);
          itemObject['markup'] = this.partiesHolder;
          itemObject['contacts'] = [contactInformation];
          partyInformation['itemObject'] = itemObject;
          lendersItems['dtoType'] = 'businessContacts';
          lendersItems['partyInformation'] = partyInformation;
        }
        newLendersData.push(lendersItems);
        this.partiesHolder = [];
      } else if (CommonService.isTruthyValue(contactInformation)) {
        //Statements
        const lendersItems = {};
        const itemObject = { contacts: [] };
        let details = '';
        const roleTypeId = contactInformation['roleTypeId'];
        details = this.formatPartiesDetailData(contactInformation, 'businessPartyName', 'businessPhone', undefined, false);
        lendersItems['itemName'] = contactInformation.role;
        lendersItems['itemValue'] = details;
        itemObject['templateType'] = this.mapRoleTypeToTemplate(roleTypeId, contactInformation?.contactName);
        itemObject['markup'] = this.partiesHolder;
        itemObject['contacts'] = [contactInformation];
        lendersItems['itemObject'] = itemObject;
        newLendersData.push(lendersItems);
        this.partiesHolder = [];
      }
      if (CommonService.isTruthyValue(newLenderRelatedParties)) {
        items = [];
        newLenderRelatedParties.forEach((contacBusiness) => {
          if (contacBusiness) {
            const contactInformation = contacBusiness;
            const contactPerson = contacBusiness.contactPersonDetails;
            if (CommonService.isTruthyValue(contactPerson)) {
              //Statements With Contact Person
              contactPerson.id = contactInformation?.id;
              contactPerson.role = contactInformation?.role;
              contactPerson.businessPartyName = contactInformation?.businessPartyName;
              contactPerson.contactName = contactInformation?.contactName;
              contactPerson.roleTypeId = contactInformation?.roleTypeId;
              const relatedOfficers = [];
              const relatedPartyItems = {};
              let details = '';
              const relatedRoleTypeId = contactPerson['roleTypeId'];
              details = this.formatPartiesDetailData(contactPerson, 'businessPartyName', 'businessPhone', undefined, false);
              contactPerson.role = this.renameLenders(contactPerson);
              relatedPartyItems['itemName'] = contactPerson.role;
              relatedPartyItems['itemValue'] = details;
              const relatedItemObject = { contacts: [] };
              relatedItemObject['templateType'] = this.mapRoleTypeToTemplate(relatedRoleTypeId, contactPerson?.contactName);
              relatedItemObject['markup'] = this.partiesHolder;
              relatedItemObject['contacts'] = [contactPerson];
              relatedPartyItems['itemObject'] = relatedItemObject;
              this.partiesHolder = [];
              if (CommonService.isTruthyValue(contactInformation)) {
                const partyInformation = {};
                let details = '';
                const relatedRoleTypeId = contactInformation['roleTypeId'];
                details = this.formatPartiesDetailData(contactInformation, 'businessPartyName', 'businessPhone', undefined, false);
                contactInformation.role = this.renameLenders(contactInformation);
                partyInformation['itemName'] = contactInformation.role;
                partyInformation['itemValue'] = details;
                const relatedItemObject = { contacts: [] };
                relatedItemObject['templateType'] = this.mapRoleTypeToTemplate(relatedRoleTypeId, contactInformation?.contactName);
                relatedItemObject['markup'] = this.partiesHolder;
                relatedItemObject['contacts'] = [contactInformation];
                partyInformation['itemObject'] = relatedItemObject;
                relatedPartyItems['dtoType'] = 'businessContacts';
                relatedPartyItems['partyInformation'] = partyInformation;
                this.partiesHolder = [];
              }
              relatedOfficers.push(relatedPartyItems);
              this.sortParties(relatedOfficers);
              items.push(...relatedOfficers);
            } else if (CommonService.isTruthyValue(contactInformation)) {
              //Statements Without Contact Person
              const relatedOfficers = [];
              const relatedPartyItems = {};
              let details = '';
              const relatedRoleTypeId = contactInformation['roleTypeId'];
              details = this.formatPartiesDetailData(contactInformation, 'businessPartyName', 'businessPhone', undefined, false);
              contactInformation.role = this.renameLenders(contactInformation);
              relatedPartyItems['itemName'] = contactInformation?.role;
              relatedPartyItems['itemValue'] = details;
              const relatedItemObject = { contacts: [] };
              relatedItemObject['templateType'] = this.mapRoleTypeToTemplate(relatedRoleTypeId, contactInformation?.contactName);
              relatedItemObject['markup'] = this.partiesHolder;
              relatedItemObject['contacts'] = [contactInformation];
              relatedPartyItems['itemObject'] = relatedItemObject;
              this.partiesHolder = [];
              relatedOfficers.push(relatedPartyItems);
              this.partiesHolder = [];
              this.sortParties(relatedOfficers);
              items.push(...relatedOfficers);
            }
          }
        });
        //outsideForEachLoop
        items.sort((a, b) => (a.itemName < b.itemName ? -1 : 1));
        items.forEach((filteredData) => {
          newLendersData.push(filteredData);
        });
        items = [];
      }
    });
    const index = this.findLastIndexofArray(this.partyDetails);
    this.partyDetails.splice(index + 1, 0, ...newLendersData);
  }

  public processPartyElement(
    name: string,
    contactName: string,
    phone: string,
    email: string,
    contact: ContactDto,
    isPrimary: boolean | null
  ): string {
    const party = this.mapPartyElement(name, contactName, phone, email, contact, isPrimary);
    this.partiesHolder.push(party);

    return `${name}${contactName}${phone}${email}`;
  }

  public mapPartyElement(
    name: string,
    contactName: string,
    phone: string,
    email: string,
    contact: ContactDto,
    isPrimary: boolean
  ): TransactionInfoParty {
    const element = <TransactionInfoParty>{
      name: name,
      contactName: contactName,
      phone: phone,
      email: email,
      principalId: contact.principalId,
      isPrimary: isPrimary,
      contactType: contact.principalType,
      contactId: contact.contactId,
      additionalInfo: <PartiesAdditionalInfoDto>{
        workPhone: contact.businessPhones,
        mobilePhone: contact.cellulars,
        homePhone: contact.homePhones,
        workFax: contact.businessFaxes,
        forwardingPhone: contact.forwardingPhones,
        homeFax: contact.homeFaxes,
        currentAddress: contact.currentaddress,
        forwardingAddress: contact.forwardingAddress,
      }
    };

    return element;
  }

  public mapRoleTypeToTemplate(roleTypeId: number, contactName: string): string {
    switch (roleTypeId) {
      case 690: // 'Business Source'
        if (contactName && contactName.length > 0) {
          return 'attorneyAgent';
        } else {
          return 'org';
        }
      case 2368: // 'Buyer’s RE Broker Transaction Coordinator'
        if (contactName && contactName.length > 0) {
          return 'attorneyAgent';
        } else {
          return 'orgWithBadge';
        }
      case 2371: // 'Seller’s RE Broker Transaction Coordinator'
        if (contactName && contactName.length > 0) {
          return 'attorneyAgent';
        } else {
          return 'orgWithBadge';
        }
      case 686: // 'Seller Broker Agent'
        if (contactName && contactName.length > 0) {
          return 'SellerBrokerAgent';
        } else {
          return 'org';
        }

      case 685: // 'Buyer Broker Agent'
        if (contactName && contactName.length > 0) {
          return 'SellerBrokerAgent';
        } else {
          return 'org';
        }

      default:
        if (contactName && contactName.length > 0) {
          return 'attorneyAgent';
        } else {
          return 'org';
        }
    }
  }

  public mapPrincipalTypeToTemplate(itemObject: { contacts: any[] }): string {
    const contacts = itemObject['contacts'];
    if (contacts && contacts.length > 0) {
      const firstContact = contacts[0];
      const principalType = firstContact['principalType'];
      switch (principalType) {
        case 'Individual':
          return 'individual';
        case 'Husband/Wife':
          return 'husbandWife';
        case 'Business Entity':
          if (firstContact.contactName && firstContact.contactName.length > 0) {
            return 'businessTrust';
          } else {
            return 'orgNoContactName';
          }
        case 'Trust/Estate':
          if (firstContact.contactName && firstContact.contactName.length > 0) {
            return 'businessTrust';
          } else {
            return 'orgNoContactName';
          }
        default:
          return 'org';
      }
    } else {
      return 'org';
    }
  }

  public renameLenders(party: PartiesDetailDto): string {
    let result = party.role;
    const role = party.role;
    const roleId = party['roleTypeId'];
    if (roleId === 3220 || role === 'Lender- Loan Officer') {
      result = 'Loan Officer';
    }
    if (roleId === 3221 || role === 'Lender- Processor') {
      result = 'Loan Processor';
    }

    return result;
  }

  ngOnDestroy(): void {
    if (this.subscribeObjectList.length > 0) {
      this.subscribeObjectList.forEach((s) => {
        if (s) {
          s.unsubscribe();
        }
      });
    }
  }
}
