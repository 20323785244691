import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef,Component, OnDestroy, OnInit, ViewEncapsulation, NgZone, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConvertTimePipe } from '@igps/pipe';
import { debounceTime, filter, finalize,map,Subscription, switchMap } from 'rxjs';
import { statusList } from 'src/app/models/StatusList';
import { getUserInfo, getTimezone, timezone$ } from '@igps/client-root-config'
import { UserInfo } from 'src/app/models/UserInfo';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/global/services/utility.service';

import { ActionService } from 'src/app/global/services/action.service';
import { EmailModel } from 'src/app/global/models/EmailModel';
import { Assignee } from 'src/app/models/emailBasicInfo';
import { FileSearchService } from 'src/app/global/services/file-search.service';


@Component({
  selector: 'app-email-status',
  templateUrl: './email-status.component.html',
  styleUrls: ['./email-status.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmailStatusComponent implements OnInit, OnDestroy {

  @Input() public emailId: any;
  search: EventEmitter<any> = new EventEmitter<any>();
  searchEventSubscription!: Subscription;

  searchText: string = "";
  isUsersLoading: boolean = false;
  currentDate = new Date();
  emailInfoForm!: FormGroup;
  statusList = statusList
  minDate!: any;
  staffList!: Assignee[];
  staffListBackup!: Assignee[];
  staffsListSubscription$!: Subscription;
  controlNames = { fcStatus: 'fcStatus', fcTatDate: 'fcTatDate', fcAssignedTo: 'fcAssignedTo', fcTatTime: 'fcTatTime' }
  userInfo!: UserInfo;
  maxDate!: Date;
  selecedTime!: string;
  userSelectedTime!: string
  timeFormat!: string;
  disabledTimeControl: boolean = true;
  selectedTimeZone!: any;
  setAssigneeValueEvent: EventEmitter<string> = new EventEmitter<string>();
  emailResponse: EmailModel;
  timezoneSubscription!: Subscription;
  timezone: { name: string; value: string; abbreviation: string } = { name: "", value: "", abbreviation: "" };
  isEmail: boolean = false;
  emailProps = {}
  public taskSource: number;
  processName: any;

  constructor(private _formBuilder: FormBuilder, private SearchResultService: FileSearchService, private actionservice: ActionService,private cdref: ChangeDetectorRef, private utilityservice: UtilityService, private _datePipe: ConvertTimePipe, private zone: NgZone, private router: Router) {

  }


  ngOnInit(): void {
    this.timezone = getTimezone();
    this.timezoneSubscription = timezone$.subscribe((timezone: { name: string; value: string; abbreviation: string }) => {
      this.timezone = timezone;
      if (this.emailResponse) {
        this.setFormValues();
      }
    });
    this.userInfo = getUserInfo();
    this.maxDate = new Date();
    this.maxDate?.setFullYear(this.currentDate?.getFullYear() + 1);

    this.SearchResultService.getprocessdetails(sessionStorage.getItem('ActiveProcessId')).subscribe((res: any) => {
      if (res) {
        this.taskSource = res.taskSource;
        this.processName = res.name;
      }
    })

    this.getEmailDetails();

    if (!this.emailResponse) {
      if (this.emailResponse) {
        this.initializeForm();
        let processId: any = sessionStorage.getItem('ActiveProcessId')?.toString()
        this.getAssignedToList(processId, false);
      }
    }

    const searchObservable = this.search.asObservable().pipe(
      map((d: any) => {
        this.isUsersLoading = true;
        this.searchText = d;
        return this.searchText;
      }),
      map((d: any) => {
        this.staffList = [];
        if (d.trim().length === 0) {
          this.isUsersLoading = false;
          this.staffList = JSON.parse(JSON.stringify(this.staffListBackup)) as Assignee[];
          this.cdref.detectChanges();
        }
        return d;
      }),
      filter((d: any) => d.trim().length > 0),
      debounceTime(500),
      switchMap((d: any) => {
        const prdsysId = getUserInfo().productionSystem.id;
        return this.actionservice.getAssigneeOnSearch(prdsysId, d).pipe(
          finalize(() => {
            this.isUsersLoading = false;
            this.cdref.detectChanges();
          })
        );
      })
    );
    this.searchEventSubscription = searchObservable.subscribe(assigneeData => {
      if (this.searchText.trim().length !== 0) {
        assigneeData = assigneeData.map((d: any) => {
          return { ...d, role: d.role.charAt(0).toUpperCase() + d.role.slice(1).toLowerCase() };
        });
        this.staffList = assigneeData;
      }
      this.cdref.detectChanges();
    });
  }

  getEmailDetails() {
    this.zone.run(() => {
      this.actionservice.getEmailDetails(this.emailId).subscribe((emailData: EmailModel) => {

        this.emailResponse = emailData;
        this.initializeForm();

        let processId: any = sessionStorage.getItem('ActiveProcessId')?.toString()
        this.getAssignedToList(processId, false);
        sessionStorage.setItem('emailAssignedTo', emailData.assignedTo);
        sessionStorage.setItem('emailStatusId', emailData.status.toString());
      });
    });
  }

  onAssignToValueChange(event: MatSelectChange) {
    this.zone.run(() => {
      const assignedTo: string = event.value;
      if (this.emailResponse) {
        if (assignedTo === 'null') {
          this.utilityservice.setSpinner(true);
          this.actionservice.UnAssignEmail(this.emailResponse.id).pipe(
            finalize(() => {
              this.utilityservice.setSpinner(false);
            })
          ).subscribe({
            next: () => {
              this.utilityservice.openToast('This email is Unassigned', false);
              const state = {
                emailId: this.emailResponse.id, emailStatusId: this.emailResponse.status,
                assigneeId: null, onShoreToggle: false
              }
              sessionStorage.setItem("AssigneeLoad", JSON.stringify(state));
              this.getEmailDetails();
            },
            error: (error: HttpErrorResponse) => {
              this.utilityservice.openToast(error?.error.Detail, true);
              this.staffList = JSON.parse(JSON.stringify(this.staffListBackup)) as Assignee[];
              const assignee = !!this.emailResponse.assignedTo ? this.staffList.find((item) => item.id === this.emailResponse.assignedTo)!.id : 'null';
              this.setAssigneeValueEvent.emit(assignee);
            }
          });
        }
        else {
          let assigneId: any | undefined = '';
          assigneId = this.staffList?.find((sl) => sl?.id === assignedTo)?.id
          this.utilityservice.setSpinner(true);
          this.actionservice.ReAssignEmail(this.emailResponse.id, assigneId).pipe(
            finalize(() => {
              this.utilityservice.setSpinner(false);
            })
          ).subscribe({
            next: () => {
              
              const assignee = this.staffList.find(a => a.id === assigneId);
              this.utilityservice.openToast(`This email is Assigned to ${assignee?.name}`, false);
              const state = {
                emailId: this.emailResponse.id, emailStatusId: this.emailResponse.status,
                assigneeId: assigneId, onShoreToggle: false
              }
              sessionStorage.setItem("AssigneeLoad", JSON.stringify(state));
              this.getEmailDetails();
            },
            error: (error: HttpErrorResponse) => {
              this.utilityservice.openToast(error?.error.Detail, true);
              this.staffList = JSON.parse(JSON.stringify(this.staffListBackup)) as Assignee[];
              const assignee = !!this.emailResponse.assignedTo ? this.staffList.find((item) => item.id === this.emailResponse.assignedTo)!.id : 'null';
              this.setAssigneeValueEvent.emit(assignee);
            }
          });
        }
      }
    })
  }

  onOpenChangeAssignee() {
    setTimeout(() => {
      if (!this.utilityservice.getSpinnerValue()) {
        this.staffList = JSON.parse(JSON.stringify(this.staffListBackup)) as Assignee[];
        const assignee = !!this.emailResponse.assignedTo ? this.staffList.find((item) => item.id === this.emailResponse.assignedTo)!.id : 'null';
        this.setAssigneeValueEvent.emit(assignee);
      }
    }, 100);
  }


  getAssignedToList(processId: string, isBackground: boolean) {
    if (!isBackground) {
      this.utilityservice.setSpinner(true);
    }
    this.staffsListSubscription$ = this.actionservice.getStaffNames(processId).pipe(
      finalize(() => {
        this.utilityservice.setSpinner(false);
      })
    ).subscribe({
      next: (result) => {
        this.staffList = result as Assignee[];
        this.staffList = this.staffList.map(s => {
          return { ...s, site: (s as any).siteName }
        });

        const mySelf: Assignee = {
          id: this.userInfo.id,
          name: `Myself`,
          role: this.userInfo.role.name,
          site: this.userInfo.site.name
        }
        const unAssigned: Assignee = {
          id: "null",
          name: 'UnAssigned',
          role: '',
          site: ""
        }
        this.staffList.unshift(mySelf);
        this.staffList.unshift(unAssigned);

        this.staffList = this.staffList.map(d => {
          return { ...d, role: d.role.charAt(0).toUpperCase() + d.role.slice(1).toLowerCase() };
        })
        this.staffListBackup = JSON.parse(JSON.stringify(this.staffList)) as Assignee[];
        this.setFormValues();
      },
      error: (error: HttpErrorResponse) => {
        this.utilityservice.openToast(error?.error.Detail, true);
      }
    });
  }

  initializeForm() {
    this.zone.run(() => {
      this.emailInfoForm = this._formBuilder.group(
        {
          fcStatus: [{ value: '', disabled: true }, []],
          fcTatDate: [{ value: '', disabled: true }],
          fcAssignedTo: [{ value: '', disabled: !((this.userInfo?.assumedRoleId === 1 && !(this.emailResponse?.status === 7 || this.emailResponse?.status === 777 || this.emailResponse?.status === 6 || this.emailResponse?.status === 9)) || this.emailResponse?.status === 1000) }, []]
        });
    })
  }

  setFormValues() {

    const timezoneTime = this._datePipe.convertTimeFromOneTimeZoneToAnother(this.emailResponse.dueDate
      , 'utc', this.timezone.value);
    const orderedDateUtcTimezoneTime = this._datePipe.convertTimeFromOneTimeZoneToAnother(this.emailResponse.dueDate
      , 'utc', this.timezone.value);
    this.minDate = new Date(orderedDateUtcTimezoneTime);
    const time = this._datePipe.convertTimeFromOneTimeZoneToAnother(this.emailResponse.dueDate
      , 'utc', this.timezone.value).split('T')[1];
    this.selecedTime = this._datePipe.changeTimeFormat(time, 'hh:mm a')!;
    this.timeFormat = this._datePipe.changeTimeFormat(time, 'a')!;

    const assignee = !!this.emailResponse?.assignedTo ? this.staffList.find((item) => item.id === this.emailResponse?.assignedTo)!.id : 'null';
    this.zone.run(() => {
      this.emailInfoForm.controls[this.controlNames.fcTatDate].setValue(timezoneTime)
      this.emailInfoForm.controls[this.controlNames.fcStatus].setValue(this.statusList.find((item) => item.id === this.emailResponse?.status)!.id);
      this.setAssigneeValueEvent.emit(assignee);
    })
  }

  onBack() {
    this.navigate();
  }
  navigate() {
    if (this.userInfo.assumedRoleId == 1) {
      this.router.navigate(['manager/dashboard']);
    }
    else {
      this.router.navigate(['processor/dashboard']);
    }
  }

  ngOnDestroy(): void {
    this.staffsListSubscription$?.unsubscribe();
    this.timezoneSubscription?.unsubscribe();
    this.searchEventSubscription?.unsubscribe();
  }

}



