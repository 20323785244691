export class Events {
  public static TriggerEvents: string[] = [
    // "tasknotesaddedevent",
    // "tasknotesupdatedevent",
    // "orderrequestedevent",
    'taskduedatechangedevent',
    'taskstartedevent',
    'ordercancelledevent',
    'taskreturnedtoigpsevent',
    'taskcancelledevent',
    'taskassignedevent',
    'taskunassignedevent',
    'taskreturnedtobranchevent',
    'tasksenttoqueryevent',
    'taskputonholdevent',
    'taskremoveholdevent',
    'taskescalatedtoonshoreevent',
    'taskcompletedevent',
    'taskcompletedwithexceptionsevent',
    'tasksenttoprocessorevent',
    // "taskdocumentaddedevent",
    // "taskdocumentremovedevent",
    // "taskdocumentphrasevalueupdatedevent"
    // "taskdocumentassociatepackageevent",
    // "errorreportedbyigniteevent",
    // "taskstartedactionerrorigniteevent",
    // "returntobranchactionerrorigniteevent",
    // "completedactionerrorigniteevent",
    // "completedwithexceptionsactionerrorigniteevent"
    'taskcancelledbyipsevent',
    // "createnewtaskevent",
    "taskunlinkfromemailevent",

    'emailsenttoqueryevent',
    'emailputonholdevent',
    'emailremoveholdevent',
    'emailescalatedtoonshoreevent',
    'emailsenttoprocessorevent',
    'emaillostandfoundevent',
    'emailmarkasspam',
    'emailremovespam',
    'emailassignedevent',
    'emailunassignedevent',
    // 'emailprioritychangedevent',
    // 'emailnotesaddedevent',
    // 'emailnotesupdatedevent',
    // 'emailnotesstatuschangedevent',
    'emaillinkedtotaskevent',
    'emailmovedevent',
    'emailstartedevent',
    'emailreceivedevent',
  ];
  public static NotesEvent: string[] = [
    'tasknotesaddedevent',
    'tasknotesupdatedevent',
    'errorreportedbyigniteevent',
    'emailnotesaddedevent',
    'emailnotesupdatedevent',
    'emailnotesstatuschangedevent',
  ];
  public static DocumentEvent: string[] = [
    'taskdocumentaddedevent',
    'taskdocumentremovedevent',
    'taskdocumentphrasevalueupdatedevent',
    'taskdocumentassociatepackageevent',
  ];
}
