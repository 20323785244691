import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    private showSpinnerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public showSpinner: Observable<boolean> = this.showSpinnerSubject.asObservable();
    private showGridSpinnerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public showGridSpinner: Observable<boolean> = this.showGridSpinnerSubject.asObservable();
    public showToast: EventEmitter<{ message: string, isError: boolean }> = new EventEmitter<{ message: string, isError: boolean }>();

    getSpinnerValue(){
        return this.showSpinnerSubject.value;
    }

    setSpinner(value: boolean) {
        this.showSpinnerSubject.next(value);
    }

    setGridSpinner(value: boolean) {
        this.showGridSpinnerSubject.next(value);
    }

    openToast(message: string, isError?: boolean) {
        this.showToast.emit({"message": message, "isError": isError ? true : false});
      }

}
