import { ChangeDetectorRef,Component,OnInit ,OnDestroy,ViewChild } from '@angular/core';
import { emailDashConstant } from './global/constants/email-search-constants';
import { ToastComponent } from '@ui-core/toast';
import { Subscription } from 'rxjs';
import { UtilityService } from './global/services/utility.service';


@Component({
  selector: 'file-search',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit,OnDestroy {

  toastEventSubscription!: Subscription;
  spinnerSubscription!: Subscription;

  @ViewChild(ToastComponent) toaster!: ToastComponent;
  showSpinner: boolean = false;

  constructor(private utilityService: UtilityService, private cdref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.spinnerSubscription = this.utilityService.showSpinner.subscribe(d => { this.showSpinner = d; this.cdref.detectChanges(); });
    this.toastEventSubscription = this.utilityService.showToast.subscribe(d => { this.openToast(d.message, d.isError); this.cdref.detectChanges(); });
  }

  ngOnDestroy(): void {
    emailDashConstant.emailId = '';
    emailDashConstant.selectedEmailStatusId = 0;
    emailDashConstant.emailAssignedTo = '';
    emailDashConstant.onShoreQueue = false;
  };

  openToast(message: string, isError: boolean = false) {
    this.toaster.toastMessage = message;
    this.toaster.actionRightText = 'close';
    this.toaster.showToast = true;
    this.toaster.error = isError;
    this.toaster.toasterTimeout();
    this.cdref.detectChanges();
  }
  title = 'igps-file-search';
}
