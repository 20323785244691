<div class="search-container" [ngClass]="{'emailsearch':taskSource==2}">
  <div class="file__search__subnav__breadcrumb">
    <span class="search-container__header">Search for File</span>
  </div>
  <div class="middle">
    <article class="search-container__middle" [ngClass]="{'emailsearch':taskSource==2}">
      <div class="search-container__file-search-container m-r-8" name="file-search-container"
        [ngClass]="{'emailsearch':taskSource==2}">
        <mat-form-field class="ig2 search-container__file-search-dropdown">
          <mat-select [(value)]="selectedType" [disableOptionCentering]="true"
            panelClass="search-container__dropdown-panel">
            <mat-option *ngFor="let searchType of searchTypesKeys" [value]="searchType">
              {{ searchTypes[searchType] }}
            </mat-option>

          </mat-select>
        </mat-form-field>

        <form class="search-container__search-field" name="search-container__search-field" clickOutside
          (clickOutside)="closeSearchResultsDropdown()">
          <mat-icon class="search__clear" name="search__clear" aria-label="clear icon" *ngIf="isThereSearchInput"
            (click)="clearSearchFieldAndSearchRecents()">
            <img class="search__clear__image" src="assets/icons/icon-clear-dark.svg" alt="clear dark" />
          </mat-icon>
          <input #searchInput name="searchInput" autocomplete="off" spellcheck="false" class="global__search-input"
            type="text" placeholder="{{ placeholder }}" (focus)="showSearchResults()"
            (keyup)="onSearchInputChange(searchInput.value, $event.keyCode)" maxlength="150" />
          <button name="searchIcon" type="button" (click)="respondToSearchRequest()">
            <img class="search__icon__image" src="assets/svg/icon-search-light.svg" alt="search" />
          </button>

          <app-search-results-dropdown [filteredRecentSearches]="recentSearches" [selectedType]="selectedType"
            [placeholder]="placeholder" [predictiveSearchResults]="predictiveSearchResults" [queryField]="queryField"
            [showSearchResultsDropdown]="showSearchResultsDropdown"
            [searchMode]="searchMode"></app-search-results-dropdown>
        </form>
      </div>
    </article>
  </div>
  <span *ngIf="taskSource==1" class="search-container__back-btn">
    <svg (click)="onBack()" viewBox="0 0 24 24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m4.14 12.374 4.943 5.189a.49.49 0 0 0 .542.105.538.538 0 0 0 .164-.847L5.7 12.528h13.83a.47.47 0 0 0 .341-.159.52.52 0 0 0 .134-.366.535.535 0 0 0-.134-.365.487.487 0 0 0-.341-.159H5.699l4.09-4.293a.527.527 0 0 0 .147-.37.547.547 0 0 0-.146-.371.488.488 0 0 0-.707-.001l-4.944 5.19a.534.534 0 0 0-.144.326v.089c.01.12.058.236.144.326l.001-.001Z"
        fill="#0044E9"></path>
    </svg>
    <span (click)="onBack()" class="text">Return to Dashboard</span>
  </span>
</div>