export class ListDataModel{
    public data!: any[]
    public columns!: ListColumnDataModel[]
    public defaultSortColumnName?: string
    public defaultSortDirection?: string
}


export interface ListColumnDataModel {
    selected?: string;
    columnName: string;
    displayName: string;
    type: string;
    columnWidth: number;
    searchDropDown?: boolean,
    searchDropDownValues?: any[],
    dropDown?: boolean,
    dropDownValues?: any[]
}