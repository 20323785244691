import { Directive, ElementRef, HostListener, Output, EventEmitter, NgZone } from '@angular/core';

@Directive({
  selector: '[clickOutside]',
})
export class OutsideClickDirective {
  constructor(private _elementRef: ElementRef, private zone: NgZone) {}

  @Output('clickOutside') clickOutside: EventEmitter<any> = new EventEmitter();

  // TODO: lint code review
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  @HostListener('document:click', ['$event.target']) onMouseEnter(targetElement) {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.clickOutside.emit(null);
    }
  }
}
